import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { InputPasswordProject } from "../components/FormikInputs/FormikInputs";
import Loader from "react-loader-spinner";
import Cookies from "js-cookie";
import { useAlert } from "react-alert";

const PasswordUpdate = ({ setToken }) => {
  const { tmptoken } = useParams();
  const alert = useAlert();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();

  /* Modal */
  const customStyles = {
    overlay: { zIndex: 99 },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderColor: "#618f92",
      background: "#E4FDD9",
    },
  };
  Modal.setAppElement("#root");

  /* Validation */
  const validationShematPassword = Yup.object().shape({
    password: Yup.string().required("Obligatoire"),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "La confirmation doit être identique au mot de passe"
      )
      .required("Obligatoire"),
  });

  /* function submit */
  const submitNewPassword = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/user/update/${tmptoken}`,
        values
      );
      if (response.status === 200) {
        alert.show(response.data.message, { type: "success" });
        Cookies.set("afdd_co_to", response.data.token);
        setToken(response.data.token);
        setSubmitting(false);
        navigate("/");
      }
    } catch (error) {
      alert.show(error.response.data.message, { type: "error" });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/password_reinit/${tmptoken}`
        );
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        alert.show(error.response.data.message, {
          type: "error",
          position: "middle",
          timeout: 0,
        });
        setIsLoading(false);
      }
    };
    fetchData();
  }, [tmptoken, alert]);

  return isLoading ? (
    <div className="pages loading">
      <Loader type="Circles" color="#618f92" height={60} width={60} />
    </div>
  ) : data ? (
    <Modal id="reini-modal" isOpen={true} style={customStyles}>
      <div className="btn-close-modal-container">
        <button className="btn btn-close-modal" onClick={() => navigate("/")}>
          X
        </button>
      </div>
      <h1 className="rest-pass-title">Réinitialisation de mot de passe</h1>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={validationShematPassword}
        onSubmit={(values, { setSubmitting }) => {
          submitNewPassword(values, { setSubmitting });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="rest-pass-form">
            <InputPasswordProject
              classnamelabel="label-modal"
              classnameinput="input-modal"
              label="Nouveau mot de passe"
              name="password"
              placeholder="ex: azerty"
            />
            <InputPasswordProject
              classnamelabel="label-modal"
              classnameinput="input-modal"
              label="Confirmez mot de passe"
              name="confirmPassword"
              placeholder="Confirmation"
            />
            {isSubmitting ? (
              <Loader
                className="loaderSignup"
                type="Circles"
                color="#618f92"
                height={60}
                width={60}
              />
            ) : (
              <button className="btn btn-modal" type="submit">
                Soumettre
              </button>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  ) : null;
};

export default PasswordUpdate;
