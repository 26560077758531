import "./HandlePerfume.css";
import { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import Loader from "react-loader-spinner";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalizeFirstLettre, sortObj } from "../../helper/helper";

const HandlePerfume = ({ token }) => {
  const alert = useAlert();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [perfumeChange, setPerfumeChange] = useState(false);
  const [inputPerfume, setInputPerfume] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/backoffice`
        );
        setData(response.data.perfume);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [perfumeChange]);

  const handleChangeInputPerfume = (event) => {
    return setInputPerfume(
      event.target.value !== "" ? capitalizeFirstLettre(event.target.value) : ""
    );
  };

  const onSubmitPerfume = async (event) => {
    event.preventDefault();
    try {
      setPerfumeChange(true);
      if (inputPerfume !== "") {
        const perfumeArr = sortObj(
          [...data, { name: inputPerfume, value: inputPerfume }],
          "name"
        );

        const response = await axios.put(
          `${process.env.REACT_APP_URL}/backoffice/config`,
          { perfume: perfumeArr },
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );
        setInputPerfume("");
        setPerfumeChange(false);
        return alert.show(response.data.message, { type: "success" });
      } else {
        alert.show("Entrez un nom de parfum", {
          position: "middle",
          type: "error",
        });
      }
    } catch (error) {
      console.log(error.message);
      setPerfumeChange(false);
      return alert.show(error.response.data.message, { type: "error" });
    }
  };

  const deletePerfume = async (index) => {
    try {
      setPerfumeChange(true);
      const newPerfumeArr = [];
      data.forEach(
        (perfume, indexPerfume) =>
          index !== indexPerfume && newPerfumeArr.push(perfume)
      );
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/backoffice/config`,
        { perfume: newPerfumeArr },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );
      setPerfumeChange(false);
      return alert.show(response.data.message, { type: "success" });
    } catch (error) {
      console.log(error.message);
      setPerfumeChange(false);
      return alert.show(error.response.data.message, { type: "error" });
    }
  };

  return isLoading ? (
    <div>
      <Loader type="Circles" color="#618f92" height={60} width={60} />
    </div>
  ) : (
    <div className="perfume-container">
      <form onSubmit={onSubmitPerfume} id="new-perfume">
        <input
          onChange={handleChangeInputPerfume}
          value={inputPerfume}
          type="text"
          placeholder="Saisissez un parfum"
        />

        {perfumeChange ? (
          <Loader type="Circles" color="#618f92" width={40} />
        ) : (
          <button type="submit" className="btn">
            Ajoutez
          </button>
        )}
      </form>
      <table id="perfume">
        <thead>
          <tr>
            <th colSpan={2}>Parfums proposés</th>
          </tr>
        </thead>
        <tbody>
          {data.map((perfume, index) => (
            <tr key={index}>
              <td>{perfume.name}</td>
              <td>
                <FontAwesomeIcon
                  className="icon-delete-perfume"
                  onClick={() => deletePerfume(index)}
                  icon="trash"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HandlePerfume;
