import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import ScrollMenu from "../ScrollMenu/ScrollMenu";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { intlFormat, capitalizeFirstLettre } from "../../helper/helper";

const ProductElement = ({ data, dataConfig, token, openModal, reduction }) => {
  const alert = useAlert();
  const navigate = useNavigate();

  const [perfume, setPerfume] = useState();
  const [product, setProduct] = useState();
  const [order, setOrder] = useState(false);
  const [userId, setUserId] = useState();

  const submitPerfume = (data) => {
    const obj = { perfume: data.value };
    setPerfume(obj);
  };

  useEffect(() => {
    const addProductBasket = async (product, token) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/user/basket`,
          { perfume: product.perfume, product_id: product._id },
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );
        if (order) {
          setUserId(response.data);
        }

        if (!order) {
          alert.show(`Le produit ${data.name} a été placé dans votre panier`, {
            type: "success",
          });
        }
      } catch (error) {
        alert.show(error.response.data.message, { type: "error" });
      }
    };

    if (perfume && product) {
      addProductBasket({ ...perfume, _id: product._id }, token);
      setPerfume();
      setProduct();
    } else {
      if (product) {
        alert.show("Vous n'avez pas choisi de parfum", { type: "error" });
        setProduct();
      }
    }
    document.body.style.overflow = "auto";
  }, [perfume, product, alert, token, openModal, order, data]);

  useEffect(() => {
    if (order && userId) {
      navigate(`/order`);
      setOrder(false);
    }
  }, [order, userId, navigate]);

  const submitProduct = (product) => {
    setProduct(product);
    alert.removeAll();
  };

  const choseScent = (data) => {
    alert.show(
      <div className="alert-by-product-container">
        <div className="alert-by-product-item">
          <h3 className="alert-by-product-title">{data.name}</h3>
          <div className="alert-by-product-img-container">
            <img
              className="alert-by-product-img"
              src={data.picture}
              alt={data.description}
            />
          </div>
          <ScrollMenu
            options={dataConfig.perfume}
            onChange={submitPerfume}
            label="Choisir un parfum"
          />
        </div>

        <div className="alert-by-product-nav">
          <button
            onClick={() => submitProduct(data)}
            className="btn btn-cancel"
          >
            Validez
          </button>
          <button
            className="btn btn-backOffice"
            onClick={() => {
              alert.removeAll();
              return (document.body.style.overflow = "auto");
            }}
          >
            Annulez
          </button>
        </div>
      </div>,
      { type: "info", timeout: 0, position: "middle" }
    );
  };

  const orderProduct = () => {
    if (!token) {
      openModal(1);
    } else {
      setOrder(true);
      choseScent(data);
    }
  };

  const addBasket = () => {
    if (!token) {
      openModal(1);
    } else {
      choseScent(data);
    }
  };

  return (
    <div className="products">
      <div className="info-col-1">
        <div className="image-product">
          {reduction ? (
            <div className="icon-promotion">
              <FontAwesomeIcon icon="heart" />
              <span>Promo</span>
            </div>
          ) : null}
          <img src={data.picture} alt="" />
        </div>
        <div className="product-price">
          <span className={reduction ? "product-old-price" : ""}>
            {data.price} €
          </span>
          {reduction ? (
            <span>
              {intlFormat(data.price - data.price * (data.reduction / 100))}
            </span>
          ) : null}
        </div>
        <nav className="product-nav">
          <button onClick={addBasket} className="btn">
            Ajoutez au panier
          </button>
          <button className="btn btn-backOffice" onClick={orderProduct}>
            Commandez
          </button>
        </nav>
      </div>
      <div className="info-col-2">
        <h3>{capitalizeFirstLettre(data.name)}</h3>
        <p>{capitalizeFirstLettre(data.description)}</p>
        <Link to={`/products/${data._id}`}>Détails de l'article</Link>
      </div>
    </div>
  );
};

export default ProductElement;
