import "./UpDateContactInformation.css";
import Loader from "react-loader-spinner";
import { useAlert } from "react-alert";
import { Formik, Form } from "formik";
import { lightFormat } from "date-fns";
import * as Yup from "yup";
import "yup-phone-lite";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  CheckBoxProject,
  InputPasswordProject,
  InputTextProject,
} from "../../components/FormikInputs/FormikInputs";

const UpDateContactInformation = ({
  dataUser,
  token,
  setToken,
  handleChangeData,
}) => {
  const alert = useAlert();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .phone("FR", "Entrez un numero valide")
      .required("Obligatoire"),
    firstName: Yup.string().required("Obligatoire"),
    lastName: Yup.string().required("Obligatoire"),
    birthDate: Yup.date(),
    email: Yup.string().email("Email invalide").required("Obligatoire"),
  });

  const validationSchemaPassword = Yup.object().shape({
    oldPassword: Yup.string().required("Obligatoire"),
    newPassword: Yup.string().required("Obligatoire"),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword"), null],
        "La confirmation doit être identique au mot de passe"
      )
      .required("Obligatoire"),
  });

  const updateInformationSubmit = async (values) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/user/profile/update`,
        { ...values },
        { headers: { authorization: "Bearer " + token } }
      );
      alert.show(response.data.message, { type: "success" });
      handleChangeData();
      if (response.data.token) {
        Cookies.remove("afdd_co_to");
        Cookies.set("afdd_co_to", response.data.token, { secure: true });
        setToken(response.data.token);
      }
    } catch (error) {
      alert.show(error.response.data.message, { type: "error" });
    }
  };

  const deleteProfile = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/user/profile/delete`,
        {
          headers: {
            authorization: "Bearer " + token,
          },
          params: { deleteProfile: true },
        }
      );
      navigate("/");
      Cookies.remove("afdd_co_to");
      setToken(null);
      alert.show(response.data.message, { type: "success" });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          phone: dataUser.phone ? 0 + `${dataUser.phone}` : "",
          firstName: dataUser.firstName || "",
          lastName: dataUser.lastName || "",
          birthDate: dataUser.account.birthDate
            ? lightFormat(new Date(dataUser.account.birthDate), "yyyy-MM-dd")
            : "",
          email: dataUser.email,
          newLetter: dataUser.account.newLetter || false,
        }}
        onSubmit={updateInformationSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values }) => (
          <Form id="form-contact-information">
            <h2>Coordonnées</h2>
            <div className="account-content">
              <div className="user-civility">
                <InputTextProject
                  label="Prénom :"
                  name="firstName"
                  placeholder="ex:George"
                />
                <InputTextProject
                  label="Nom de famille :"
                  name="lastName"
                  placeholder="ex:Dupond"
                />
                <CheckBoxProject
                  name="newLetter"
                  label="Recevoir nos offres et promotions"
                  checked={values.newLetter}
                  classnamelabel="checkbox-signup"
                />
              </div>
              <div className="user-account-content">
                <InputTextProject label="Email :" name="email" />
                <InputTextProject
                  label="Date de naissance :"
                  name="birthDate"
                  type="date"
                />
                <InputTextProject label="Téléphone :" name="phone" />
                <p>
                  Seuls les numéros de téléphone fixe ou mobile de norme
                  française sont valides.
                </p>
              </div>
            </div>
            {isSubmitting ? (
              <div className="loader">
                <Loader type="Circles" width={40} color="#618F92" />
              </div>
            ) : (
              <div className="btn-profile-information">
                <button type="submit">Validez</button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchemaPassword}
        onSubmit={updateInformationSubmit}
      >
        {({ isSubmitting }) => (
          <Form id="form-contact-password">
            <h2>Changez de mot de passe</h2>
            <InputPasswordProject
              label="Mot de passe actuel :"
              name="oldPassword"
            />
            <InputPasswordProject
              label="Nouveau mot de passe :"
              name="newPassword"
            />
            <InputPasswordProject
              label="Confirmation de mot de passe :"
              name="confirmPassword"
            />
            {isSubmitting ? (
              <div className="loader">
                <Loader type="Circles" width={40} color="#618F92" />
              </div>
            ) : (
              <div className="btn-profile-information">
                <button type="submit">Validez</button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <button onClick={deleteProfile} className="delete-profile">
        Supprimez ce compte
      </button>
    </>
  );
};

export default UpDateContactInformation;
