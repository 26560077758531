import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  InputAreaProject,
  InputTextProject,
} from "../FormikInputs/FormikInputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "react-loader-spinner";
import axios from "axios";
import { useAlert } from "react-alert";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Obligatoire"),
  email: Yup.string().email("Email non valide").required("Obligatoire"),
  object: Yup.string(),
  message: Yup.string().required("Vous n'avez pas écris de message"),
});

const FormContact = ({ userInfo }) => {
  const alert = useAlert();

  const handleSubmit = async (values, { setSubmitting }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_URL}/email/sending`,
      {
        subject: values.object,
        content: 3,
        owner: true,
        contact: {
          email: values.email,
          message: values.message,
          name: values.name,
        },
      }
    );
    if (response.status === 200) {
      setSubmitting(false);
      alert.show(response.data.message, { type: "success" });
    }
  };
  return (
    <div className="form-contact-container">
      <h3>Vous pouvez nous envoyez directement un message</h3>
      <h4>
        N'hésitez pas à nous laisser vos commentaires.
        <FontAwesomeIcon className="icon-form-contact" icon="smile-wink" />
      </h4>
      <Formik
        initialValues={{
          name: userInfo ? userInfo.userName : "",
          email: userInfo ? userInfo.email : "",
          object: "",
          message: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit(values, { setSubmitting })
        }
      >
        {({ isSubmitting }) => (
          <Form className="form-container">
            <InputTextProject
              classnamelabel="form-contact-label"
              classnameinput="form-contact-input"
              classnameerror="form-contact-error"
              label="Nom *"
              name="name"
              placeholder="Saisissez votre nom"
            />
            <InputTextProject
              classnamelabel="form-contact-label"
              classnameinput="form-contact-input"
              classnameerror="form-contact-error"
              placeholder="Saisissez votre email"
              type="email"
              label="Email *"
              name="email"
            />
            <InputTextProject
              classnamelabel="form-contact-label"
              classnameinput="form-contact-input"
              classnameerror="form-contact-error"
              placeholder="Saisissez le sujet"
              label="Sujet "
              name="object"
            />
            <InputAreaProject
              label="Message *"
              name="message"
              placeholder="Rédigez votre message"
              classnamelabel="form-contact-label"
              classnamearea="form-contact-area"
              classnameerror="form-contact-error"
              rows={6}
            />
            <p style={{ alignSelf: "self-end" }}>* Champ obligatoire</p>
            {isSubmitting ? (
              <div className="loader">
                <Loader type="Circles" width={60} height={60} color="#618f92" />
              </div>
            ) : (
              <button type="submit" className="btn btn-form-contact">
                Envoyez votre message
              </button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormContact;
