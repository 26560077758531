import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { InputTextProject } from "../FormikInputs/FormikInputs";
import MondialModal from "../MondialModal/MondialModal";
import axios from "axios";
import Loader from "react-loader-spinner";
import { useAlert } from "react-alert";
import { intlFormat } from "../../helper/helper";

const AdressForm = ({ submit, setSubmit, setNewDelivery, title }) => {
  const validationYup = Yup.object().shape({
    firstName: Yup.string().required("Obligatoire"),
    lastName: Yup.string().required("Obligatoire"),
    adress: Yup.string().required("Obligatoire"),
    postalCode: Yup.number().required("Obligatoire"),
    city: Yup.string().required("Obligatoire"),
    phone: Yup.number().required("Obligatoire"),
  });

  const submitForm = (values) => {
    const adress = { ...submit };
    const newAdress = {
      firstName: values.firstName,
      lastName: values.lastName,
      adress: {
        road: values.adress,
        postalCode: values.postalCode,
        city: values.city,
      },
      phone: values.phone,
    };
    if (!adress.residence) {
      adress.residence = newAdress;
    } else {
      adress.delivery = newAdress;
      setNewDelivery(false);
    }
    setSubmit(adress);
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        adress: "",
        postalCode: "",
        city: "",
        phone: "",
      }}
      enableReinitialize
      onSubmit={submitForm}
      validationSchema={validationYup}
    >
      {() => (
        <Form id="order-form">
          <h3>{title}</h3>
          <InputTextProject label="Nom" name="lastName" placeholder="Nom" />
          <InputTextProject
            label="Prénom"
            name="firstName"
            placeholder="Prénom"
          />
          <InputTextProject
            label="Adresse"
            name="adress"
            placeholder="Adresse"
          />
          <InputTextProject
            label="Code postal"
            name="postalCode"
            placeholder="Code postal"
          />
          <InputTextProject label="Ville" name="city" placeholder="Ville" />
          <InputTextProject
            label="Numéro de téléphone"
            name="phone"
            placeholder="Numéro de téléphone"
          />
          <div className="btn-adress-container">
            <button type="submit" className="btn">
              Validez
            </button>
            <button onClick={() => setNewDelivery(false)} className="btn">
              Annulez
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const Adress = ({ data, title, callBack, className }) => {
  let func = false;
  if (callBack) {
    func = true;
  }
  return (
    <div
      onClick={func ? () => callBack(data) : null}
      className={className ? `order-adress ${className}` : "order-adress"}
    >
      <h3>{title}</h3>
      {data.lastName && <div>{data.lastName}</div>}
      {data.firstName && <div>{data.firstName}</div>}
      {data.adress.road && <div>{data.adress.road}</div>}
      {data.adress.postalCode && <div>{data.adress.postalCode}</div>}
      {data.adress.city && <div>{data.adress.city}</div>}
      {data.phone && <div>{data.phone}</div>}
    </div>
  );
};

const OrderForm = ({ handleChangeStep, setUserDelivery, token, basket }) => {
  const alert = useAlert();

  const [isLoading, setIsLoading] = useState(true);
  const [dataUser, setDataUser] = useState();
  const [delivery, setDelivery] = useState({});
  const [newDelivery, setNewDelivery] = useState(false);
  const [deliveryArr, setDeliveryArr] = useState([]);
  const [fees, setFees] = useState();
  const [transport, setTransport] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const feesArr = (data) => {
      const keys = Object.keys(data);
      const feesObj = {};

      let weightTotal = 0;
      basket.forEach((elem) => (weightTotal += elem.weight * elem.quantity));
      keys.forEach((key, index) => {
        if (index !== 2) {
          feesObj[key] = data[key].filter(
            (elem) => elem.min <= weightTotal && elem.max >= weightTotal
          )[0];
        }
      });
      return feesObj;
    };

    const fetchDataUser = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/user/profile`,
          {
            headers: { authorization: "Bearer " + token },
          }
        );
        setDataUser(response.data);
        if (response.data.adress) {
          setDelivery({
            residence: {
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              adress: { ...response.data.adress },
              phone: response.data.phone,
            },
          });
        }
        fetchDataFees();
      } catch (error) {
        alert.show(error.response.data.message, { type: "error" });
      }
    };

    const fetchDataFees = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/backoffice`
        );
        setFees(feesArr(response.data.fees));
        setIsLoading(false);
      } catch (error) {
        alert.show(error.response.data.message, { type: "error" });
      }
    };

    fetchDataUser();
  }, [token, alert, basket]);

  const newAdress = () => {
    const newDelivery = delivery;
    delete newDelivery.delivery;
    setDelivery(newDelivery);
    if (dataUser.delivery && dataUser.delivery.length !== 0) {
      const arrDeliveryTransport =
        transport && transport["mondial-relay"]
          ? dataUser.delivery.filter((delivery) => delivery.relay === true)
          : dataUser.delivery;
      const arrDelivery = arrDeliveryTransport.map((elem) => (
        <Adress
          callBack={handleClickAdress}
          key={elem._id}
          title="Livrer à cette adresse"
          data={elem}
          className="saved-adress"
        />
      ));
      if (dataUser && dataUser.adress) {
        arrDelivery.unshift(
          <Adress
            callBack={handleClickAdress}
            key={dataUser._id}
            title="Chez vous"
            data={dataUser}
            className="saved-adress"
          />
        );
      }

      return setDeliveryArr(arrDelivery);
    }
    setNewDelivery(true);
  };

  const handleClickAdress = (data) => {
    setDelivery({ ...delivery, delivery: data });
    setDeliveryArr([]);
  };

  const handleClickContinue = async () => {
    if (delivery.residence) {
      if (Object.keys(delivery).length !== 0) {
        if (transport) {
          try {
            const dataToUpdate = { ...delivery.residence };
            if (Object.keys(transport)[0] === "mondial-relay") {
              if (
                !delivery.delivery ||
                (delivery.delivery && !delivery.delivery.relay)
              ) {
                return alert.show("Vous devez choisir un point relay", {
                  type: "error",
                });
              }
            }
            if (delivery.delivery) {
              dataToUpdate.delivery = delivery.delivery;
            }
            await axios.put(
              `${process.env.REACT_APP_URL}/user/profile/update`,
              dataToUpdate,
              {
                headers: {
                  authorization: "Bearer " + token,
                },
              }
            );
            setUserDelivery({ ...delivery, transport: { ...transport } });
            handleChangeStep(2);
          } catch (error) {
            alert.show(error.response.data.message, { type: "error" });
          }
        } else {
          alert.show("Il vous faut choisir un mode de livraison", {
            type: "error",
          });
        }
      } else {
        alert.show("information de livraison manquante", { type: "error" });
      }
    } else {
      alert.show("information de livraison manquante", { type: "error" });
    }
  };

  const handleChangeTransport = (event) => {
    const value = event.target.value;
    setTransport({ [`${value}`]: fees[value].price });
    if (value === "mondial-relay") {
      setIsOpenModal(true);
    }
  };

  const handleCloseModal = (values) => {
    if (values && values.shopCode) {
      const deliveryObj = delivery;
      deliveryObj.delivery = {
        shopCode: values.shopCode,
        firstName: values.Nom,
        adress: {
          road: values.Adresse1,
          postalCode: values.CP,
          city: values.Ville,
        },
        relay: true,
      };
      setDelivery(deliveryObj);
    } else {
      if (
        (delivery.delivery && !delivery.delivery.relay) ||
        !delivery.delivery
      ) {
        alert.show(
          "Vous devez choisir un point relay ou un autre mode de livraison",
          { type: "error" }
        );
      }
    }
    setIsOpenModal(false);
  };

  return isLoading ? (
    <div className="loading">
      <Loader type="Circles" height={60} width={60} color="#618F92" />{" "}
    </div>
  ) : (
    <>
      <MondialModal isOpen={isOpenModal} closeModal={handleCloseModal} />
      <div className="delivery-container">
        <div className="adress-container">
          {dataUser.firstName &&
          dataUser.lastName &&
          dataUser.adress &&
          !delivery.delivery &&
          deliveryArr.length === 0 ? (
            <Adress
              title={
                transport && transport.colissimo
                  ? "Chez vous"
                  : transport && transport["mondial-relay"]
                  ? "Adresse de facturation"
                  : "Chez vous"
              }
              data={dataUser}
            />
          ) : delivery &&
            delivery.residence &&
            !delivery.delivery &&
            !newDelivery &&
            deliveryArr.length === 0 ? (
            <Adress title="Chez vous" data={delivery.residence} />
          ) : (!delivery.delivery &&
              !newDelivery &&
              deliveryArr.length === 0) ||
            !delivery.residence ? (
            <AdressForm
              title={"Votre adresse/Adresse de facturation"}
              setSubmit={setDelivery}
            />
          ) : null}

          {delivery.delivery ? (
            <Adress data={delivery.delivery} title="Adresse de livraison" />
          ) : null}

          {(dataUser.adress || delivery.residence) &&
          !newDelivery &&
          deliveryArr.length === 0 &&
          (!transport || (transport && !transport["mondial-relay"])) ? (
            <button onClick={() => newAdress()} className="btn">
              Autre adresse
            </button>
          ) : null}

          {deliveryArr.length !== 0 ? (
            <div className="delivery">
              <h3>Adresses enregistrées</h3>
              {deliveryArr}
              <button
                onClick={() => {
                  setDeliveryArr([]);
                  return setNewDelivery(true);
                }}
                className="btn"
              >
                Autre adresse
              </button>
            </div>
          ) : null}

          {newDelivery &&
          ((transport && !transport["mondial-relay"]) || !transport) ? (
            <AdressForm
              title="A cette adresse"
              submit={delivery}
              setSubmit={setDelivery}
              setNewDelivery={setNewDelivery}
            />
          ) : transport && transport["mondial-relay"] ? (
            <button className="btn" onClick={() => setIsOpenModal(true)}>
              Choisir un point relay
            </button>
          ) : null}
        </div>

        <form className="transport-container">
          <h3>Choisir un type de transport</h3>
          <label>
            <input
              type="radio"
              id="colissimo"
              value="colissimo"
              name="transport"
              onChange={handleChangeTransport}
            />
            Colissimo
          </label>
          <div>{intlFormat(fees["colissimo"].price)}</div>
          <label>
            <input
              type="radio"
              id="mondial-relay"
              value="mondial-relay"
              name="transport"
              onChange={handleChangeTransport}
            />
            Mondial relay
          </label>
          <div>{intlFormat(fees["mondial-relay"].price)}</div>
        </form>
      </div>

      <div className="order-form-btn">
        <button onClick={() => handleChangeStep(0)} className="btn">
          Retour à votre commande
        </button>
        <button type="submit" onClick={handleClickContinue} className="btn">
          Continuez
        </button>
      </div>
    </>
  );
};

export default OrderForm;
