import "../assets/css/home.css";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { intlFormat } from "../helper/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [carouselItems, setCarouselItems] = useState([]);
  const [carouselPromotions, setCarouselPromotions] = useState([]);

  const CarouselContainer = ({ data, reduction }) => {
    return (
      <div className="carousel-items-container">
        <img src={data.user.picture} alt="" />
        <p className="legend">
          <span>{data.user.name}</span>
          <span className={reduction ? "carousel-old-price" : ""}>
            {data.user.price} €
          </span>
          {reduction ? (
            <span>
              {intlFormat(
                data.user.price - data.user.price * (reduction / 100)
              )}
            </span>
          ) : null}
        </p>
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseBackoffice = await axios.get(
          `${process.env.REACT_APP_URL}/backoffice`
        );
        setData(responseBackoffice.data);
        const onDisplayResponse = await axios.get(
          `${process.env.REACT_APP_URL}/product?onDisplay=true`
        );
        const onHeartResponse = await axios.get(
          `${process.env.REACT_APP_URL}/product?onHeart=true`
        );
        const promotions = await axios.get(
          `${process.env.REACT_APP_URL}/promotion?articles=true`
        );

        const promotionArrId =
          promotions.status === 200
            ? promotions.data.map((article) => article.product._id)
            : [];
        if (onDisplayResponse.status === 200) {
          const items = [];
          onDisplayResponse.data.products.forEach(
            (product) =>
              promotionArrId.indexOf(product._id) === -1 &&
              items.push(<CarouselContainer key={product._id} data={product} />)
          );
          setCarouselItems(items);
          const itemsHeart = [];
          if (promotions.data.length !== 0) {
            promotions.data.forEach((article) =>
              itemsHeart.push(
                <CarouselContainer
                  key={article.product._id}
                  data={article.product}
                  reduction={article.reduction}
                />
              )
            );
          }
          onHeartResponse.data.products.forEach(
            (product) =>
              promotionArrId.indexOf(product._id) === -1 &&
              itemsHeart.push(
                <CarouselContainer key={product._id} data={product} />
              )
          );
          setCarouselPromotions(itemsHeart);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (hash === "") {
      return;
    }
    const id = hash.replace("#", "");
    setTimeout(() => {
      const elementToScroll = document.getElementById(id);

      elementToScroll &&
        elementToScroll.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    }, 1000);
  }, [hash]);

  const handleClickItemCarousel = (index, item) => {
    navigate(`/products/${item.key}`);
  };

  return isLoading ? (
    <div className="loading">
      <Loader
        className="loader"
        type="Circles"
        color="#618f92"
        height={60}
        width={60}
      />
    </div>
  ) : (
    <>
      <div className="head-img">
        <img className="img-fond" src={data.home_picture} alt="saison" />
        <p className="img-fond-text">{data.home_picture_text}</p>
      </div>
      <section className="carousel-products container">
        <div className="home-col-1">
          <h2>Les produits du moment</h2>
          <div className="carousel-box">
            <div className="carousel-container">
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                width={300}
                showThumbs={false}
                showStatus={false}
                onClickItem={handleClickItemCarousel}
              >
                {carouselItems}
              </Carousel>
            </div>
            <p>
              &nbsp;&nbsp;&nbsp;Voici une suggestion de nos produits du moment,
              les dernières créations ou nouveaux contenants (bol,tasse,mug...).
              <br />
              &nbsp;&nbsp;&nbsp;Mais aussi les articles les plus recherchés ou
              insolites.
            </p>
          </div>
        </div>
        <div className="home-col-2">
          <h2>Nos promotions ou coup de coeur</h2>
          <div className="carousel-box">
            <div className="carousel-container">
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                width={300}
                showThumbs={false}
                showStatus={false}
                onClickItem={handleClickItemCarousel}
              >
                {carouselPromotions}
              </Carousel>
            </div>
            <p>
              &nbsp;&nbsp;&nbsp;Ici vous trouverez nos articles en promotion.
              Nos petits prix pour des idées cadeaux malin.
              <br />
              &nbsp;&nbsp;&nbsp;Ainsi que des paniers d'ensembles harmonieux
              pour offrir, collectionner ou décorer.
            </p>
          </div>
        </div>
      </section>

      <div className="description">
        <h2 className="title-description">À propos de nous</h2>
        <p id="helper">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;En 2020, Amandine crée "Au fil des
          désirs", une entreprise qui propose des bougies parfumées aux
          fragrances de Grasse. Son idée est de confectionner des bougies à base
          de cires naturelles et pour limiter son impact environnemental
          d'utiliser des contenants pouvant être réutilisés aprés la consumation
          de la cire. Elle choisit donc de créer des bougies dans des objets de
          la vie quotidienne : tasses, mugs, bonbonnières, bols...
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Le siège social est situé dans la
          région Nouvelle Aquitaine, au nord du département de la Dordogne,
          entre Nontron (20km) et Angoulême (50km) au lieu-dit Le Trieux commune
          de &nbsp;
          <a
            href="http://www.bussierebadil24.fr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bussière-Badil
          </a>
          &nbsp; en &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.guide-du-perigord.com/fr/tourisme/decouvrir/visite-du-perigord/le-perigord-vert.html"
          >
            Périgord vert
          </a>
          .
        </p>
      </div>
      <div className="helper">
        <div className="title-helper-container">
          <FontAwesomeIcon
            className="icon-helper"
            icon="fa-regular fa-lightbulb"
          />
          <h2 className="title-helper">Conseils de brûlage</h2>
        </div>
        <ul>
          <h4>
            Conseils d'utilisation pour permettre la combustion optimale de
            l'ensemble des bougies fabriquées par <span>Au fil des désirs</span>
          </h4>
          <p>
            Lors de la première utilisation, faites brûler la bougie durant ± 2h
            pour une combustion optimale , et ainsi éviter la formation de creux
            autour de la mèche.
          </p>
          <li>
            Ne pas hésitez à couper la mèche si nécessaire afin d'éviter quelle
            ne se courbe.
          </li>
          <li>Poser la bougie parfumée sur une surface plate.</li>
          <li>Allumez l'ensemble des mèches pour une parfaite combustion.</li>
          <li>
            Une fois allumée, ne pas déplacer la bougie, ni la placer dans un
            courant d'air, cela pourrait entraîner de manière irrégulière la
            fonte de la cire, et un décentrage de la mèche.
          </li>
          <li>
            Retirer tout débris d'allumettes ou autre dans la cire liquide, la
            bougie risque de brûler de façon irrégulière.
          </li>
          <li>
            Ne pas faire brûler la bougie plus de 4h, cela pourrait altérer la
            qualité de son parfum.
          </li>
          <li>
            Une fois éteinte, laissez la bougie se solidifier avant tout
            déplacement et réutilisation.
          </li>
          <li>
            Le temps de brûlage indiqué dans les détails de l'article est
            indicatif et peut varier selon l'utilisation de la bougie.
          </li>
          <li>
            Le contenant de la bougie se recycle, bien le laver à l'eau chaude
            savonneuse.
          </li>
        </ul>
      </div>
    </>
  );
};

export default Home;
