import "../assets/css/Order.css";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import OrderElement from "../components/Order/OrderElement";
import OrderForm from "../components/Order/OrderForm";
import OrderPayment from "../components/Order/OrderPayment";
import OrderValidation from "../components/Order/OrderValidation";
import { useAlert } from "react-alert";
import { intlFormat } from "../helper/helper";

const Order = ({ token }) => {
  const navigate = useNavigate();
  const alert = useAlert();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [orderList, setOrderList] = useState();
  const [workArray, setWorkArray] = useState([]);
  const [steps, setSteps] = useState([true, false, false, false]);
  const [userDelivery, setUserDelivery] = useState();
  const [ordered, setOrdered] = useState();

  /* Fetch basket and make array quantity */

  const fetchBasket = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/user/purchase?basket=true`,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );
      const promotions = await axios.get(
        `${process.env.REACT_APP_URL}/promotion?articles=true`
      );
      if (response.status === 200 && response.data.length !== 0) {
        setWorkArray(
          response.data.map((elem) => {
            promotions.status === 200 &&
              promotions.data.forEach((promo) => {
                if (elem.product === promo.product._id) {
                  elem.reduction = promo.reduction;
                }
              });
            return {
              quantity: elem.quantity || 1,
              price: elem.price,
              weight: elem.weight,
              perfume: elem.perfume,
              product: elem.product,
              _id: elem._id,
              reduction: elem.reduction || false,
            };
          })
        );
        setData(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [token]);

  useEffect(() => {
    fetchBasket();
  }, [fetchBasket]);

  /* Build component products */
  useEffect(() => {
    const removeProduct = async (productId) => {
      setData();
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_URL}/user/basket/delete/${productId}`,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );
        fetchBasket();
        if (response.data.basket.length === 0) {
          navigate("/products");
        }
        alert.show(response.data.message, { type: "success" });
      } catch (error) {
        alert.show(error.response.data.message, { type: "error" });
      }
    };
    if (!data) {
      setIsLoading(true);
      return;
    } else {
      setOrderList(
        data.map((data, index) => {
          return (
            <OrderElement
              key={data._id}
              data={data}
              className="order-element"
              index={index}
              workArray={workArray}
              setWorkArray={setWorkArray}
              removeProduct={removeProduct}
            />
          );
        })
      );
      setIsLoading(false);
    }
  }, [data, workArray, token, navigate, alert, fetchBasket]);

  /* Function steps */
  const handleChangeStep = (num) => {
    setSteps(steps.map((step, index) => (index === num ? true : false)));
  };

  /* Submit basket */
  const submitBasket = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_URL}/user/basket/update`,
        { basket: workArray },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );
      handleChangeStep(1);
    } catch (error) {
      alert.show(error.response.data.message, { type: "error" });
    }
  };

  return isLoading ? (
    <div className="loading">
      <Loader type="Circles" width={60} height={60} color="#2F4858" />
    </div>
  ) : (
    <div className="pages">
      <section className="order-step-container">
        <div className={steps[0] ? "order-step-active-first" : "order-step"}>
          Votre commande
        </div>
        <div className="test" />
        <div className={steps[1] ? "order-step-active" : "order-step"}>
          Informations
          <br /> de
          <br /> livraison
        </div>
        <div className={steps[2] ? "order-step-active" : "order-step"}>
          Paiement
        </div>
        <div className={steps[3] ? "order-step-active-last" : "order-step"}>
          Confirmation
        </div>
      </section>
      {steps[0] ? (
        <div className="order-container">
          <h1>Votre commande</h1>
          {orderList}
          <h2>
            Total de votre commande:{" "}
            {intlFormat(
              workArray
                .map((elem) =>
                  elem.reduction
                    ? (elem.price - (elem.price * elem.reduction) / 100) *
                      elem.quantity
                    : elem.price * elem.quantity
                )
                .reduce((prev, next) => prev + next)
            )}
          </h2>
          <div className="order-button">
            <button onClick={() => navigate("/products")} className="btn">
              Retour aux produits
            </button>
            <button onClick={submitBasket} className="btn">
              Continuez
            </button>
          </div>
        </div>
      ) : steps[1] ? (
        <div className="order-container">
          <OrderForm
            handleChangeStep={handleChangeStep}
            setUserDelivery={setUserDelivery}
            token={token}
            basket={workArray}
          />
        </div>
      ) : steps[2] ? (
        <div className="order-container">
          <OrderPayment
            data={data}
            workArray={workArray}
            userDelivery={userDelivery}
            token={token}
            handleChangeStep={handleChangeStep}
            setOrdered={setOrdered}
          />
        </div>
      ) : (
        ordered && (
          <div className="order-container">
            <OrderValidation ordered={ordered} token={token} />
          </div>
        )
      )}
      ;
    </div>
  );
};

export default Order;
