import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";

const ProductUpdateBox = ({ data, helpers, label }) => {
  const navigate = useNavigate();
  const alert = useAlert();

  const [quantity, setQuantity] = useState("");
  const [showInput, setShowInput] = useState(false);

  const handleChangeQuantity = (event) => setQuantity(event.target.value);
  const openQuantity = () => setShowInput(true);
  const deleteAlert = () => {
    helpers.deleteProduct(data._id);
    alert.removeAll();
  };
  const onClickDelete = () => {
    alert.show(
      <>
        <p>
          Confirmation de suppression du produit{" "}
          <span style={{ fontSize: 20, fontWeight: 600 }}>{data.name}</span>
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexShrink: 0,
            margin: "20px 0 20px 0",
          }}
        >
          <button className="btn btn-cancel" onClick={deleteAlert}>
            Supprimez
          </button>
          <button
            className="btn btn-backOffice"
            onClick={() => alert.removeAll()}
          >
            Annulez
          </button>
        </div>
      </>,
      {
        type: "info",
        timeout: 0,
        position: "middle",
      }
    );
  };

  return (
    <div className="product-box">
      <div className={label ? "product-name label-product" : "product-name"}>
        {label ? "Noms" : data.user.name}
      </div>
      <div
        className={
          label ? "product-category label-product" : "product-category"
        }
      >
        {label ? "Catégories" : data.user.category}
      </div>
      <div
        className={
          label ? "product-quantity label-product" : "product-quantity"
        }
      >
        {label ? (
          "Quantitées"
        ) : showInput ? (
          <input
            type="number"
            onChange={handleChangeQuantity}
            value={quantity}
          />
        ) : (
          <>{data.owner.quantity} </>
        )}
        {label ? null : !showInput ? (
          <FontAwesomeIcon
            className="icon-handle-product"
            onClick={openQuantity}
            icon="calculator"
          />
        ) : (
          <FontAwesomeIcon
            className="icon-handle-product"
            onClick={() => {
              helpers.changeQuantity(data._id, quantity);
              return setShowInput(false);
            }}
            icon="check"
          />
        )}
      </div>
      <div className="product-edit">
        <FontAwesomeIcon
          onClick={label ? null : () => navigate(`/product/update/${data._id}`)}
          className={label ? "" : "icon-handle-product"}
          icon="edit"
        />
      </div>
      <div className="product-delete">
        <FontAwesomeIcon
          onClick={label ? null : onClickDelete}
          className={label ? "" : "icon-handle-product"}
          icon="trash"
        />
      </div>
    </div>
  );
};

export default ProductUpdateBox;
