import "./UpDateOrder.css";
import { useState, useEffect } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import lightFormat from "date-fns/lightFormat";
import { intlFormat } from "../../helper/helper";
import ScrollMenu from "../ScrollMenu/ScrollMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UpDateOrder = ({ token }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [orderSort, setOrderSort] = useState(0);
  const [openOrder, setOpenOrder] = useState([]);
  const [openOldOrder, setOpenOldOrder] = useState([]);

  const OrderTable = ({ data, state, setState, title }) => {
    const handleOpenOrder = (index, state, setState) => {
      return setState(
        state.map((elem, position) => (position === index ? !elem : elem))
      );
    };

    const orderStatusText = ["En cours de préparation", "Expédié", "Livré"];

    return (
      <>
        <tbody>
          <tr>
            <th colSpan={6}>{title}</th>
          </tr>
          <tr className="title-tr">
            <td>Date</td>
            <td>Transporteur</td>
            <td className="table-delivery">Livraison</td>
            <td>Prix</td>
            <td>Status</td>
            <td className="icon-openning">
              <FontAwesomeIcon icon="caret-up" />
              <FontAwesomeIcon icon="caret-down" />
            </td>
          </tr>
        </tbody>

        {data && data.length !== 0 ? (
          data.map((order, index) => {
            return (
              <tbody className="order-table-container" key={order._id}>
                <tr>
                  <td>{lightFormat(new Date(order.date), "dd/MM/yyyy")}</td>
                  <td>{order.transport}</td>
                  <td className="table-delivery">
                    <ul>
                      <li>{order.delivery.road}</li>
                      <li>{order.delivery.postalCode}</li>
                      <li>{order.delivery.city}</li>
                    </ul>
                  </td>
                  <td>{intlFormat(order.price)} </td>
                  <td>{orderStatusText[order.status]}</td>
                  <td className="icon-caret-container">
                    {!state[index] ? (
                      <FontAwesomeIcon
                        onClick={() => handleOpenOrder(index, state, setState)}
                        icon="caret-down"
                      />
                    ) : (
                      <FontAwesomeIcon
                        onClick={() => handleOpenOrder(index, state, setState)}
                        icon="caret-up"
                      />
                    )}
                  </td>
                </tr>
                {state[index] ? (
                  <>
                    {order.products.map((product) => {
                      const dataUser = product.product;
                      return (
                        <tr key={product._id}>
                          <td>
                            <span>{dataUser.name}</span>
                          </td>
                          <td>
                            <img src={dataUser.picture} alt={data.name} />
                          </td>
                          <td>{product.perfume}</td>
                          <td>X {product.quantity}</td>
                          <td>
                            {intlFormat(
                              product.reduction
                                ? (dataUser.price -
                                    (dataUser.price * product.reduction) /
                                      100) *
                                    product.quantity
                                : product.quantity * dataUser.price
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td className="order-total" colSpan={6}>
                        Total de la commande
                        <span>{intlFormat(order.price)}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="order-total" colSpan={6}>
                        Frais divers
                        <span>
                          {intlFormat(
                            order.fees.transport + order.fees.transaction
                          )}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="order-total" colSpan={6}>
                        Somme payé
                        <span>
                          {intlFormat(
                            order.price +
                              order.fees.transport +
                              order.fees.transaction
                          )}
                        </span>
                      </td>
                    </tr>
                  </>
                ) : null}
              </tbody>
            );
          })
        ) : (
          <tbody className="order-no-content-container">
            <tr>
              <td colSpan={6} className="order-no-content">
                Vous n'avez aucune <span>{title}</span>. 😌
              </td>
            </tr>
          </tbody>
        )}
      </>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/user/purchase`,
          {
            headers: {
              authorization: "Bearer " + token,
            },
            params: { inOrder: true, oldOrder: true },
          }
        );
        const inOrderArr = [];
        const oldOrderArr = [];
        response.data.inOrder &&
          response.data.inOrder.length !== 0 &&
          response.data.inOrder.forEach(() => inOrderArr.push(false));
        response.data.oldOrder &&
          response.data.oldOrder.length !== 0 &&
          response.data.oldOrder.forEach(() => oldOrderArr.push(false));
        setOpenOrder(inOrderArr);
        setOpenOldOrder(oldOrderArr);
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [token]);

  const handleChangeOrder = (option) => {
    setOrderSort(option.value);
  };

  return isLoading ? (
    <div className="update-order-container loading">
      <Loader type="Circles" width={60} color="#456B76" />
    </div>
  ) : (
    <div className="update-order-container">
      <h2>Historique de vos commandes</h2>
      <section className="inOrder-container">
        <table className="ordered-table">
          <thead>
            <tr>
              <th colSpan={6}>Vos Commandes</th>
            </tr>
            <tr>
              <td colSpan={6} className="table-scroll">
                <ScrollMenu
                  onChange={handleChangeOrder}
                  initialValue={orderSort}
                  arrow
                  options={[
                    {
                      name:
                        data.inOrder.length !== 0
                          ? "Commandes en cours"
                          : "Commande en cours",
                      value: 0,
                    },
                    {
                      name:
                        data.oldOrder && data.oldOrder.length !== 0
                          ? "Anciennes commandes"
                          : "Ancienne commande",
                      value: 1,
                    },
                  ]}
                />
              </td>
            </tr>
          </thead>

          {orderSort === 0 && (
            <OrderTable
              data={data.inOrder}
              state={openOrder}
              setState={setOpenOrder}
              title={
                openOrder.length !== 0
                  ? "Commandes en cours"
                  : "Commande en cours"
              }
            />
          )}
          {orderSort === 1 && (
            <OrderTable
              data={data.oldOrder}
              state={openOldOrder}
              setState={setOpenOldOrder}
              title={
                openOldOrder.length !== 0
                  ? "Anciennes commandes"
                  : "Ancienne commande"
              }
            />
          )}
        </table>
      </section>
    </div>
  );
};

export default UpDateOrder;
