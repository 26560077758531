import "../../assets/css/ProductDetails.css";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import ScrollMenu from "../ScrollMenu/ScrollMenu";
import StarVote from "../StarVote/StarVote";
import axios from "axios";
import Loader from "react-loader-spinner";

const ProductDetails = ({ data, admin, token, openModal }) => {
  const navigate = useNavigate();
  const alert = useAlert();

  const [perfum, setPerfum] = useState();
  const [validated, setValidated] = useState(false);
  const [order, setOrder] = useState(false);
  const [isLoadingVote, setIsLoadingVote] = useState(true);
  const [userVote, setUserVote] = useState(false);
  const [average, setAverage] = useState({
    average: 0.1,
    voteNumber: "Aucun vote",
  });

  const onClickEdit = () => {
    navigate(`/product/update/${data._id}`);
  };

  useEffect(() => {
    const submitPerfume = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/user/basket`,
          { product_id: data._id, perfume: perfum },
          { headers: { authorization: "Bearer " + token } }
        );
        if (response && !order) {
          alert.show(`L'article ${data.name} a était ajouté au panier`, {
            type: "success",
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    if (!validated) {
      return;
    } else {
      submitPerfume();
      setPerfum();
      setValidated(false);
      alert.removeAll();
      document.body.style.overflow = "auto";
      if (order) {
        navigate("/order");
      }
    }
  }, [perfum, validated, data, token, alert, navigate, order]);

  const orderArticle = async () => {
    try {
      if (!token) {
        openModal(1);
      } else {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/backoffice`
        );

        const optionsPerfums = response.data.perfume;

        const handleChangePerfum = (option) => {
          setPerfum(option.value);
        };

        alert.show(
          <div className="alert-perfume">
            <h3>{data.name}</h3>
            <ScrollMenu
              label="Choisir un parfum"
              options={optionsPerfums}
              onChange={handleChangePerfum}
            />
            <div className="btn-alert-perfume">
              <button className="btn" onClick={() => setValidated(true)}>
                Validez
              </button>
            </div>
          </div>,
          { type: "info", timeout: 0, position: "middle" }
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const submitVote = async (value) => {
    if (token) {
      try {
        setIsLoadingVote(true);
        const response = await axios.put(
          `${process.env.REACT_APP_URL}/product/update/${data._id}`,
          { vote: { score: value } },
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );
        alert.show(response.data.message, { type: "success" });
        const average = {
          average: response.data.average,
          voteNumber: response.data.voteNumber,
        };
        setAverage(average);
        setIsLoadingVote(false);
        setUserVote(true);
      } catch (error) {
        alert.show(error.response.data.message, { type: "error" });
        setIsLoadingVote(false);
      }
    } else {
      openModal(1);
    }
  };
  useEffect(() => {
    const fetchUserVote = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/user/profile`,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );
      if (
        data.vote.map((elem) => elem.user).indexOf(response.data._id) !== -1
      ) {
        setUserVote(true);
      }
    };
    if (token) {
      fetchUserVote();
    }
    if (data.vote && data.vote.length !== 0) {
      const voteNumber = data.vote.map((elem) => elem.score).length;
      const average =
        data.vote
          .map((elem) => elem.score)
          .reduce((curr, prev) => curr + prev) / voteNumber;
      setAverage({ average: average, voteNumber: voteNumber });
    }
    setIsLoadingVote(false);
  }, [data, token]);

  return (
    <div className="container-article">
      <div className="header-article">
        <div className="image-article">
          <img src={data.picture} alt={data.name} />
        </div>
        <nav>
          <div className="btn-article-container">
            <button
              className="btn"
              onClick={() => {
                setOrder(true);
                return orderArticle();
              }}
            >
              Commandez
            </button>
            <button className="btn" onClick={orderArticle}>
              Ajoutez au panier
            </button>
          </div>

          {!isLoadingVote && !userVote ? (
            <div className="vote-container">
              <span>Vous aimez ? Votez !!</span>
              <FontAwesomeIcon className="icon-vote" icon="heart" />
              <StarVote
                className="star-vote-container"
                submitting={submitVote}
              />
            </div>
          ) : isLoadingVote ? (
            <Loader type="Circles" color="#618f92" width={40} />
          ) : null}
          {isLoadingVote ? (
            <Loader type="Circles" color="#618f92" width={40} />
          ) : (
            <div className="average-container">
              <div className="star-vote-container">
                <StarVote
                  className="average-star-container"
                  average={average.average}
                />
              </div>
              <div>
                <span>Nombre de vote :</span>
                {average.voteNumber}
              </div>
            </div>
          )}
          {admin ? (
            <button onClick={onClickEdit} className="btn btn-backOffice">
              Editez
            </button>
          ) : null}
        </nav>
      </div>

      <div className="info-article">
        <div className="info">
          <h4>Nom :</h4>
          <p>{data.name}</p>
        </div>
        <div className="info">
          <h4>Catégorie :</h4>
          <p>{data.category}</p>
        </div>
        <div className="info">
          <h4>Description :</h4>
          <p>{data.description}</p>
        </div>
        <div className="info">
          <h4>Temps de brûlage :</h4>
          <p>
            {data.burnTime} <span>Heures</span>
            <Link
              className="anchor-link"
              to={{ pathname: "/", hash: "#helper" }}
            >
              <FontAwesomeIcon
                className="icon-anchor"
                icon="fa-regular fa-lightbulb"
              />
              Conseils de brûlage
            </Link>
          </p>
        </div>
        <div className="info">
          <h4>Nombre de mèches :</h4>
          <p>{data.wick}</p>
        </div>
        <div className="info">
          <h4>Poids approximatif de cire :</h4>
          <p>
            {data.waxWeight} <span>Grammes</span>
          </p>
        </div>
        <div className="info">
          <h4>Temps de production avant envoi :</h4>
          <p>
            {data.productTime} <span>Jour{data.productTime > 1 && "s"}</span>
          </p>
        </div>
        <div className="info">
          <h4>Composition :</h4>
          <ul>
            <li>Cires végétales de soja et colza sans OGM</li>
            <li>Mèche en coton</li>
            <li>Parfum fabriqué à Grasse</li>
          </ul>
        </div>
        <div className="info last-info">
          <h4>Prix :</h4>
          <p>{data.price} €</p>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
