import { Formik, Form } from "formik";
import { InputTextProject } from "../FormikInputs/FormikInputs";
import Loader from "react-loader-spinner";
import axios from "axios";
import { useAlert } from "react-alert";

const ImgText = ({ token }) => {
  const alert = useAlert();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/backoffice/config`,
        values,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );
      alert.show(response.data.message, { type: "success" });
      setSubmitting(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Formik
      initialValues={{ text: "" }}
      onSubmit={(values, { setSubmitting }) =>
        handleSubmit(values, { setSubmitting })
      }
    >
      {({ isSubmitting }) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <InputTextProject
            classnamelabel="label-modal"
            classnameinput="input-modal"
            label="Entrez le nouveau titre"
            name="text"
          />
          {isSubmitting ? (
            <div className="loader">
              <Loader type="Circles" width={60} color="#456B76" />
            </div>
          ) : (
            <button className="btn" type="submit">
              Validez
            </button>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ImgText;
