import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { intlFormat } from "../../helper/helper";

const OrderElement = ({
  data,
  index,
  workArray,
  setWorkArray,
  removeProduct,
  ...props
}) => {
  const handleChangeNumber = (operator, index) => {
    if (operator === "-") {
      if (workArray[index].quantity > 1) {
        const newArr = [...workArray];
        newArr[index].quantity--;
        setWorkArray(newArr);
      }
    }
    if (operator === "+") {
      const newArr = [...workArray];
      newArr[index].quantity++;
      setWorkArray(newArr);
    }
  };
  const price = data.reduction
    ? data.price - (data.price * data.reduction) / 100
    : data.price;

  return (
    <div className={props.className}>
      <div>
        <h3>{data.name}</h3>
      </div>
      <div>
        <img src={data.picture} alt={data.name} className="order-img" />
      </div>
      <div className="order-perfume">
        <p>{data.perfume}</p>
      </div>
      <div className="order-number">
        <div>
          <FontAwesomeIcon
            onClick={() => handleChangeNumber("+", index)}
            className="order-icon"
            icon="plus"
          />
          <span>{workArray[index].quantity}</span>
          <FontAwesomeIcon
            onClick={() => handleChangeNumber("-", index)}
            className="order-icon"
            icon="minus"
          />
        </div>
      </div>
      <div className="order-price">
        <p>
          {intlFormat(price)}
          {data.reduction && " *"}
        </p>
      </div>
      <div className="order-total">
        <p>
          {intlFormat(workArray[index].quantity * price)}
          {data.reduction && " *"}
        </p>
      </div>
      <div>
        <button className="btn" onClick={() => removeProduct(data._id)}>
          <FontAwesomeIcon icon="trash" />
        </button>
      </div>
    </div>
  );
};

export default OrderElement;
