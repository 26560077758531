import { useState } from "react";
import Modal from "react-modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { InputTextProject } from "../FormikInputs/FormikInputs";
import axios from "axios";
import Loader from "react-loader-spinner";
import { useAlert } from "react-alert";

/* Modal */
const customStyles = {
  overlay: { zIndex: 99 },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderColor: "#618f92",
    background: "#E4FDD9",
  },
};
Modal.setAppElement("#root");

/* Validation */
const yupValidationForgottenPassword = Yup.object().shape({
  email: Yup.string().email("Invalide email").required("Obligatoire"),
  identified: Yup.string().required("Champ obligatoire"),
});

const ForgottenPassword = ({ modalIsOpen, closeModal }) => {
  const alert = useAlert();

  const [validated, setValidated] = useState(false);
  const [userInfo, setUserInfo] = useState();

  const checkValidation = async (value, setFieldValue, setSubmitting) => {
    try {
      setSubmitting(true);
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/user?validated=${value}`
      );
      setFieldValue({ identified: "", email: "" });
      if (response) {
        setUserInfo(response.data);
        setSubmitting(false);
        setValidated(true);
      }
    } catch (error) {
      if (error) {
        alert.show(error.response.data.message, { type: "error" });
        setSubmitting(false);
        setValidated(false);
      }
    }
  };

  return (
    <Modal
      id="forgottenPassword"
      isOpen={modalIsOpen[2]}
      onRequestClose={() => closeModal(2)}
      style={customStyles}
    >
      <div className="btn-close-modal-container">
        <button className="btn btn-close-modal" onClick={() => closeModal(2)}>
          X
        </button>
      </div>

      <Formik
        enableReinitialize
        initialValues={{
          email: userInfo ? userInfo.email : "",
          identified: userInfo ? userInfo.userName : "",
        }}
        validationSchema={yupValidationForgottenPassword}
        onSubmit={async (values) => {
          try {
            const response = await axios.put(
              `${process.env.REACT_APP_URL}/user/password/update`,
              { email: values.email, user: userInfo.userName }
            );
            if (response.status === 200) {
              alert.show(response.data.message, {
                type: "success",
                position: "middle",
                timeout: 0,
              });
              closeModal(2);
            }
          } catch (error) {
            alert.show(error.response.message, { type: "error" });
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue, setSubmitting }) => (
          <Form className="forgotten-password">
            <h1 style={{ textAlign: "center", marginBottom: 15 }}>
              Réinitialisation mot de passe
            </h1>
            <p style={{ fontSize: 20, fontWeight: "bold" }}>
              {" "}
              Email ou nom d'utilisateur valide
            </p>
            <InputTextProject
              label="Nom d'utilisatreur ou email"
              classnamelabel="label-modal"
              classnameinput="input-modal"
              name="identified"
              icon="true"
              validateduser={validated ? "true" : "false"}
            />
            {isSubmitting || validated ? null : (
              <button
                className="btn btn-modal"
                type="button"
                onClick={() =>
                  checkValidation(
                    values.identified,
                    setFieldValue,
                    setSubmitting
                  )
                }
              >
                Vérifier
              </button>
            )}
            <p style={{ fontSize: 20, fontWeight: "bold", marginTop: 20 }}>
              Adresse d'envoi du courrier pour changement de mot de passe{" "}
            </p>
            <p style={{ marginBottom: 20 }}>
              (elle peut être différente de celle du compte si le compte est
              idenfié par votre nom d'utilisateur)
            </p>
            <InputTextProject
              classnamelabel="label-modal"
              classnameinput="input-modal"
              label="Email"
              name="email"
              placeholder="ex: Lutin@test.com"
            />
            {isSubmitting ? (
              <Loader
                className="loaderSignup"
                type="Circles"
                color="#00BFFF"
                height={60}
                width={60}
                timeout={3000}
              />
            ) : (
              <button className="btn btn-modal" type="submit">
                Envoyez
              </button>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ForgottenPassword;
