import Modal from "react-modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  InputTextProject,
  InputPasswordProject,
} from "../FormikInputs/FormikInputs";
import axios from "axios";
import Loader from "react-loader-spinner";
import Cookies from "js-cookie";
import { useAlert } from "react-alert";

/* Modal */
const customStyles = {
  overlay: { zIndex: 99 },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderColor: "#618f92",
    background: "#E4FDD9",
  },
};
Modal.setAppElement("#root");

/* Validation */
const yupValidationLogin = Yup.object().shape({
  userName: Yup.string().required("Obligatoire"),
  password: Yup.string().required("Obligatoire"),
});

const Login = ({ modalIsOpen, closeModal, toggleModal, setToken }) => {
  const alert = useAlert();

  /* Forgotten password */
  const forgottenPassword = async () => {
    try {
      toggleModal(2);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Modal
      id="login"
      isOpen={modalIsOpen[1]}
      onRequestClose={() => closeModal(1)}
      style={customStyles}
    >
      <div className="btn-close-modal-container">
        <button className="btn btn-close-modal" onClick={() => closeModal(1)}>
          X
        </button>
      </div>

      <h1 className="login-title">Se Connecter</h1>
      <Formik
        initialValues={{ userName: "", password: "" }}
        validationSchema={yupValidationLogin}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_URL}/login`,
              values
            );
            if (response.status === 200) {
              Cookies.set("afdd_co_to", response.data.token, { secure: true });
              setToken(response.data.token);
              setSubmitting(false);
              closeModal(1);
            }

            return;
          } catch (error) {
            console.log(error.response.data);
            if (error.response.data) {
              alert.show(error.response.data.message, { type: "error" });
            }
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="login-form">
            <InputTextProject
              classnameinput="input-modal"
              label="Nom d'utilisateur ou email"
              name="userName"
              placeholder="Nom d'utilisateur ou email"
            />
            <InputPasswordProject
              classnameinput="input-modal"
              label="Mot de passe"
              name="password"
              placeholder="Mot de passe"
            />

            <div className="forgotten-password" onClick={forgottenPassword}>
              <span> Mot de passe oublié ?</span>
            </div>

            {isSubmitting ? (
              <Loader
                className="loaderSignup"
                type="Circles"
                color="#618f92"
                height={60}
                width={60}
                timeout={3000}
              />
            ) : (
              <button className="btn btn-modal" type="submit">
                Connexion
              </button>
            )}
          </Form>
        )}
      </Formik>
      <div onClick={() => toggleModal(0)} className="toggle-modal">
        Pas encore de compte ? Inscrivez-vous ?
      </div>
    </Modal>
  );
};
export default Login;
