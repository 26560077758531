import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import {
  InputTextProject,
  InputAreaProject,
} from "../FormikInputs/FormikInputs";
import { withoutAccent } from "../../helper/helper";
import ScrollMenu from "../ScrollMenu/ScrollMenu";
import * as Yup from "yup";
import axios from "axios";
import Loader from "react-loader-spinner";
import { useAlert } from "react-alert";

const Promotions = ({ token }) => {
  const alert = useAlert();

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${process.env.REACT_APP_URL}/product`);
      const arrProducts = response.data.products.map((product) => {
        return {
          value: product._id,
          name: `${product.user.name} ref: ${product.owner.productRef}`,
        };
      });
      arrProducts.unshift({
        name: "Tout les produits",
        value: response.data.products.map((product) => product._id),
      });
      setData(arrProducts);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  /* Yup schema */
  const dateToday = new Date();
  const today = new Date(
    `${dateToday.getFullYear()}-${
      dateToday.getMonth() + 1
    }-${dateToday.getDate()}`
  );

  const validationSchemaYup = Yup.object().shape({
    title: Yup.string().required("Obligatiore"),
    description: Yup.string().required("Obligatiore"),
    type: Yup.number().min(0).max(3).required("Obligatiore"),
    startDate: Yup.date()
      .min(today, "La date ne peut être anterieur à aujourd'huit")
      .required("Obligatoire"),
    endDate: Yup.date().when(
      ["startDate"],
      (startDate, yup) =>
        startDate &&
        yup
          .min(startDate, "La fin de période ne peut être avant le début")
          .required("Obligatoire")
    ),
    article: Yup.array()
      .ensure()
      .when(["type"], {
        is: 0,
        then: Yup.array().min(1, "Choisir au moin 1 produit"),
      }),
    reduction: Yup.number()
      .min(1, "La reduction doit être de 1% minimum")
      .required("Obligatoire"),
    minSum: Yup.number().when(["type"], {
      is: (value) => (value !== 0 ? true : false),
      then: Yup.number()
        .min(1, "La commande doit être de 1€ minimum")
        .required("Obligatoire"),
    }),
    code: Yup.string()
      .min(6, "Doit contenir au moin 6 caractères")
      .max(8, "8 caractère maximun")
      .required("Obligatoire"),
  });

  const promotionType = [
    { name: "Promotion sur un/des article(s)", value: 0 },
    { name: "Promotion sur commande avec prix minimum", value: 1 },
    { name: "Promotion sur l'article le moins chère", value: 2 },
    {
      name: "Promotion sur commande avec prix minimum et valeur fixe",
      value: 3,
    },
  ];

  const handleSubmit = async (
    values,
    { setSubmitting, setFieldError, resetForm }
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/promotion`,
        values,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );

      alert.show(response.data.message, { type: "success" });
      resetForm();
    } catch (error) {
      if (error.response.status === 409) {
        setFieldError("code", error.response.data.message);
      } else {
        console.log(error.message);
      }
    }
    setSubmitting(false);
  };

  return isLoading ? (
    <div className="loading">
      <Loader type="Circles" width={30} color="#456B76" />
    </div>
  ) : (
    <div>
      <Formik
        initialValues={{
          title: "",
          description: "",
          type: "",
          startDate: "",
          endDate: "",
          article: [],
          reduction: "",
          minSum: "",
          code: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchemaYup}
      >
        {({ isSubmitting, setFieldValue, values, getFieldMeta }) => (
          <Form className="promotions-form">
            <InputTextProject
              name="title"
              label="Nom de la promotion"
              placeholder="ex: Déstockage/Fête des Mères"
            />
            <InputAreaProject
              label="Définir la promotion"
              name="description"
              placeholder="ex: Profitez de cette promotion pour la fête des mère"
            />
            <ScrollMenu
              label="Type de promotion"
              options={promotionType}
              onChange={(option) => setFieldValue("type", option.value)}
            />
            <div className="error">{getFieldMeta("type").error}</div>
            <InputTextProject
              type="date"
              name="startDate"
              label="Date de début de la promotion"
            />
            <InputTextProject
              type="date"
              name="endDate"
              label="Date de fin de la promotion"
            />
            {getFieldMeta("type").value === 0 ? (
              <ScrollMenu
                onChange={(option) => {
                  const arrProducts =
                    getFieldMeta("type").value !== 0
                      ? []
                      : Array.isArray(option.value) === true
                      ? option.value
                      : values.article.indexOf(option.value) === -1
                      ? [...values.article, option.value]
                      : values.article.filter((id) => id !== option.value);

                  setFieldValue("article", arrProducts);
                }}
                label="Choisir le/les produit(s)"
                options={data}
              />
            ) : null}
            <div>{getFieldMeta("article").error}</div>
            {getFieldMeta("type").value === 0 ? (
              <ul className="ul">
                {getFieldMeta("article").value.map((id) => (
                  <li className="backoffice-li" key={id}>
                    {data.filter((product) => product.value === id)[0].name}
                  </li>
                ))}
              </ul>
            ) : null}
            <InputTextProject
              label="Montant de la réduction"
              name="reduction"
              placeholder={
                getFieldMeta("type").value !== 3
                  ? "ex: 5 (En pourcentage)"
                  : "ex: 10 (En euro)"
              }
            />
            {(getFieldMeta("type").value === 1 ||
              getFieldMeta("type").value === 3 ||
              getFieldMeta("type").value === 2) && (
              <InputTextProject
                label="Minimun de la commande"
                name="minSum"
                placeholder="ex: 25€ de commande"
              />
            )}

            <InputTextProject
              name="code"
              label="Code promotionnel"
              placeholder="ex: DEST1022"
              value={
                getFieldMeta("code").value === ""
                  ? getFieldMeta("title").value !== "" &&
                    getFieldMeta("startDate").value !== "" &&
                    getFieldMeta("reduction").value !== ""
                    ? setFieldValue(
                        "code",
                        withoutAccent(
                          `${getFieldMeta("title")
                            .value.slice(0, 3)
                            .toUpperCase()}${getFieldMeta(
                            "startDate"
                          ).value.slice(5, 7)}${getFieldMeta(
                            "reduction"
                          ).value.slice(0, 3)}`
                        )
                      )
                    : ""
                  : getFieldMeta("code").value
              }
            />
            {isSubmitting ? (
              <Loader type="Circles" width={30} color="#456B76" />
            ) : (
              <button type="submit" className="btn">
                Créez
              </button>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Promotions;
