import { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import axios from "axios";
import { useAlert } from "react-alert";

const ChangeOnDisplay = ({ token, onHeart }) => {
  const alert = useAlert();

  const [isLoading, setIsLoading] = useState(true);
  const [inputSearch, setInputSearch] = useState("");
  const [inputOnDisplay, setInputOnDisplay] = useState([]);
  const [onDisplayList, setOnDisplayList] = useState([]);

  useEffect(() => {
    const fecthData = async () => {
      try {
        const inputOnDisplayArr = [];
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/product?name=${inputSearch}`
        );

        setOnDisplayList(
          response.data.products.map((elem, index) => {
            inputOnDisplayArr.push(
              onHeart ? elem.owner.onHeart : elem.owner.onDisplay
            );
            const handleChangeOnDisplay = async (event, index) => {
              inputOnDisplayArr[index] = event.target.checked;
              try {
                const response = await axios.put(
                  `${process.env.REACT_APP_URL}/product/update/${elem._id}`,
                  onHeart
                    ? { onHeart: inputOnDisplayArr[index] }
                    : { onDisplay: inputOnDisplayArr[index] },
                  { headers: { authorization: "Bearer " + token } }
                );
                alert.show(response.data.message, { type: "success" });
              } catch (error) {
                console.log(error.message);
              }
              setInputOnDisplay(inputOnDisplayArr);
            };
            return (
              <div key={elem._id} className="tab-products-list">
                <span>{elem.user.name}</span>
                <span>{elem.owner.productRef}</span>
                <label className="checkbox-signup" htmlFor="check">
                  <input
                    name="check"
                    type="checkbox"
                    value={inputOnDisplay[index]}
                    onChange={(event) => handleChangeOnDisplay(event, index)}
                    checked={inputOnDisplayArr[index]}
                  />
                </label>
              </div>
            );
          })
        );

        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    fecthData();
  }, [inputSearch, inputOnDisplay, token, alert, onHeart]);

  const handleChangeSearch = (event) => {
    return setInputSearch(event.target.value);
  };

  return isLoading ? (
    <div className="loader">
      <Loader type="Circles" width={60} color="#618F92" />
    </div>
  ) : (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h2>{onHeart ? "Vos coups de coeur" : "Vos produits en présentation"}</h2>
      <div className="product-on-display-container">
        <div className="filters-product">
          <span>Recherchez par Nom :</span>
          <input
            type="text"
            onChange={handleChangeSearch}
            value={inputSearch}
          />
        </div>
        <div className="label-tab-products-list">
          <span>Nom</span>
          <span>Référence</span>
          <span>Ajouter ou retirer</span>
        </div>
        <div className="on-display-products-list">{onDisplayList}</div>
        <div className="products-chosen"></div>
      </div>
    </div>
  );
};

export default ChangeOnDisplay;
