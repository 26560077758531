import { useState, useEffect } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import ProductUpdateBox from "../Product/ProductUpdateBox";
import { useAlert } from "react-alert";

const FindProduct = ({ token }) => {
  const alert = useAlert();
  const [inputSearch, setInputSearch] = useState("");
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const handleChangeInputSearch = (event) => {
    setInputSearch(event.target.value);
  };

  useEffect(() => {
    const updateQuantity = async (id, value) => {
      setIsLoading(true);
      try {
        const dataToUpdate = { quantity: value };
        const response = await axios.put(
          `${process.env.REACT_APP_URL}/product/update/${id}`,
          dataToUpdate,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );
        if (response.status === 200) {
          alert.show(response.data.message, { type: "success" });
        }
      } catch (error) {
        if (error.response.status === 400) {
          alert.show(error.response.data.message, { type: "error" });
        }
      }
    };
    const deleteProduct = async (id) => {
      setIsLoading(true);
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_URL}/product/${id}`,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );
        if (response.status === 200) {
          alert.show(response.data.message, { type: "success" });
        }
      } catch (error) {
        if (error.response.status === 400) {
          alert.show(error.response.data.message, { type: "error" });
        }
      }
    };
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/product?name=${inputSearch}`
        );
        response.data.helpers = {
          changeQuantity: updateQuantity,
          deleteProduct: deleteProduct,
        };
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [alert, inputSearch, isLoading, token]);
  return (
    <div>
      <section className="filters-product">
        <span>Recherchez par Nom :</span>
        <input
          type="text"
          onChange={handleChangeInputSearch}
          value={inputSearch}
        />
      </section>
      {isLoading ? (
        <div>
          <Loader type="Circles" color="#618f92" height={60} width={60} />
        </div>
      ) : (
        <>
          <ProductUpdateBox key="000" label={true} />

          <div className="product-array">
            {data.products.map((elem) => (
              <ProductUpdateBox
                key={elem._id}
                data={elem}
                helpers={data.helpers}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FindProduct;
