import { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import HomePictureUpdate from "../components/Backoffice/HomePictureUpdate";
import NewProduct from "../components/Backoffice/NewProduct";
import FindProduct from "../components/Backoffice/FindProduct";
import Promotions from "../components/Backoffice/Promotions";
import ImgText from "../components/Backoffice/ImgText";
import ChangeOnDisplay from "../components/Backoffice/ChangeOnDisplay";
import HandlePerfume from "../components/Backoffice/HandlePerfume";
import OrderStatus from "../components/Backoffice/OrderStatus";

const BackOffice = ({ admin, token }) => {
  const navigate = useNavigate();

  /* fetch data */
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenComponent, setIsOpenComponent] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/backoffice`
      );
      setData(response.data);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  /* Handle change config */
  const handleChangeIsOpenComponent = (index) => {
    const componentArr = [
      <HomePictureUpdate token={token} />,
      <ImgText token={token} />,
      <ChangeOnDisplay token={token} />,
      <ChangeOnDisplay onHeart token={token} />,
      <NewProduct data={data} token={token} />,
      <FindProduct token={token} />,
      <Promotions token={token} />,
      <HandlePerfume token={token} />,
      <OrderStatus token={token} />,
    ];
    return setIsOpenComponent(componentArr[index]);
  };
  return isLoading ? (
    <div className="pages loading">
      <Loader
        className="loader"
        type="Circles"
        color="#618f92"
        height={60}
        width={60}
      />
    </div>
  ) : admin ? (
    <div className="container pages">
      <h1 className="backoffice-title">Configuration de votre site</h1>
      <div className="backoffice-container">
        <nav className="backoffice-nav">
          <h2>Que voulez vous faire ?</h2>
          <h3>Page d'accueil</h3>
          <ul className="ul">
            <li
              className="backoffice-li"
              onClick={() => handleChangeIsOpenComponent(0)}
            >
              Changez l'image d'accueil
            </li>
            <li
              className="backoffice-li"
              onClick={() => handleChangeIsOpenComponent(1)}
            >
              Changez le titre de l'image
            </li>
            <li
              className="backoffice-li"
              onClick={() => handleChangeIsOpenComponent(2)}
            >
              Changez les produits en présentation
            </li>
            <li
              className="backoffice-li"
              onClick={() => handleChangeIsOpenComponent(3)}
            >
              Changez les coups de coeur
            </li>
          </ul>
          <h3>Produits</h3>
          <ul className="ul">
            <li
              className="backoffice-li"
              onClick={() => handleChangeIsOpenComponent(4)}
            >
              Nouveau produit
            </li>
            <li
              className="backoffice-li"
              onClick={() => handleChangeIsOpenComponent(5)}
            >
              Modifiez ou Supprimez un produit
            </li>
            <li
              className="backoffice-li"
              onClick={() => handleChangeIsOpenComponent(6)}
            >
              Créez un code promotionnel
            </li>
            <li
              className="backoffice-li"
              onClick={() => handleChangeIsOpenComponent(7)}
            >
              Ajoutez ou supprimez des parfums
            </li>
          </ul>
          <h3>Commandes</h3>
          <ul className="ul">
            <li
              className="backoffice-li"
              onClick={() => handleChangeIsOpenComponent(8)}
            >
              Status des commandes
            </li>
          </ul>
        </nav>
        {isOpenComponent ? (
          <section className="backoffice-active-work">
            {isOpenComponent}
          </section>
        ) : null}
      </div>
    </div>
  ) : (
    <div className="pages">
      <h2 style={{ textAlign: "center", padding: 20 }}>
        Vous n'êtes pas autorisé sur cette page
      </h2>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button className="btn" onClick={() => navigate("/")}>
          Retour à l'acceuil
        </button>
      </div>
    </div>
  );
};

export default BackOffice;
