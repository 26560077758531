import "./Footer.css";
import gitHubMark from "../../assets/img/GitHub-Mark.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoGoogleFont from "../../assets/img/google-fonts-logo.png";
import logoNetlify from "../../assets/img/netlify.svg";

const Footer = () => {
  return (
    <div className=" footer-container">
      <div className="legal-notice">
        <h4>Mentions légales</h4>
        <Link to="/legal_notice/mentions-legales">
          Mentions légales et politique
          <br />
          de confidentialité
        </Link>
        <Link to="/legal_notice/cgv">Conditions générales de vente</Link>
      </div>
      <div className="partners">
        <h4>Partenaires</h4>
        <div className="font-awesome">
          <a href="https://fontawesome.com/license/free">
            <FontAwesomeIcon
              className="icon-partners"
              icon="fa-regular fa-font-awesome"
            />
            Font Awesome
          </a>
        </div>
        <div className="google-font">
          <a href="https://fonts.google.com/knowledge/glossary/licensing">
            <img src={logoGoogleFont} alt="logo google font" /> Google Font
          </a>
        </div>
        <div className="netlify">
          <a href="https://www.netlify.com/">
            <img src={logoNetlify} alt="logo netlify" />
            Netlify
          </a>
        </div>
      </div>
      <div className="dev-container">
        <h4>Web développeur :</h4>
        <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
          Blonde Christophe
        </span>
        <div className="link-dev">
          <a href="https://github.com/ChristopheBlonde">
            <img src={gitHubMark} alt="GitHub mark" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
