import "./OrderStatus.css";
import { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import axios from "axios";
import { lightFormat } from "date-fns";
import { intlFormat } from "../../helper/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAlert } from "react-alert";
import ScrollMenu from "../ScrollMenu/ScrollMenu";

const OrderStatus = ({ token }) => {
  const alert = useAlert();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [orderList, setOrderList] = useState(0);
  const [inOrderList, setInOrderList] = useState([]);
  const [oldOrderList, setOldOrderList] = useState([]);
  const [refrechData, setRefrechData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/ordered`,
          {
            headers: {
              authorization: "Bearer " + token,
            },
            params: { inOrder: true, oldOrder: true },
          }
        );
        response.data &&
          setInOrderList(
            response.data.filter((order) => order.status !== 2).map(() => false)
          );
        response.data &&
          setOldOrderList(
            response.data.filter((order) => order.status === 2).map(() => false)
          );
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [token, refrechData]);

  const OrderTableList = ({ data, title, state, setState }) => {
    const [status, setStatus] = useState(data.map(() => false));

    const scrollOptions = [
      { name: "En cours de préparation", value: 0 },
      { name: "Expédié", value: 1 },
      { name: "Livré", value: 2 },
    ];

    const handleChangeStatus = (index) => {
      setStatus(
        status.map((bool, position) => (position === index ? !bool : bool))
      );
    };
    const openDetails = (index) => {
      setState(
        state.map((elem, position) => (index === position ? !elem : elem))
      );
    };
    const onSubmitStatus = async (value, orderId, index, initialValue) => {
      try {
        if (initialValue !== value) {
          const response = await axios.put(
            `${process.env.REACT_APP_URL}/ordered/${orderId}`,
            {
              status: value,
            },
            {
              headers: {
                authorization: "Bearer " + token,
              },
            }
          );
          alert.show(response.data.message, { type: "success" });
          handleChangeStatus(index);
          setRefrechData(!refrechData);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    const statusText = ["En cours de préparation", "Expédié", "Livré"];

    return (
      <table className="order-list-table">
        <thead>
          <tr>
            <th colSpan={4}>{title}</th>
          </tr>
        </thead>
        <tbody>
          <tr className="title-list-table">
            <td>Date</td>
            <td>Client</td>
            <td>Statut</td>
            <td>
              <FontAwesomeIcon icon="edit" />
            </td>
            <td>
              <FontAwesomeIcon icon="caret-up" />
              <FontAwesomeIcon icon="caret-down" />
            </td>
          </tr>
        </tbody>

        {data.map((order, index) => (
          <tbody key={order._id}>
            <tr className={index === data.length - 1 ? "last-order" : ""}>
              <td>{lightFormat(new Date(order.date), "dd/MM/yyyy")}</td>
              <td>{order.client}</td>
              <td>
                {status[index] ? (
                  <ScrollMenu
                    options={scrollOptions}
                    onChange={(value) =>
                      onSubmitStatus(
                        value.value,
                        order._id,
                        index,
                        order.status
                      )
                    }
                    initialValue={order.status}
                  />
                ) : (
                  statusText[order.status]
                )}
              </td>
              <td>
                <FontAwesomeIcon
                  onClick={() => handleChangeStatus(index)}
                  className="icon-edit-status"
                  icon="edit"
                />
              </td>
              <td>
                <FontAwesomeIcon
                  onClick={() => openDetails(index)}
                  className="icon-open-detail"
                  icon={state[index] ? "caret-up" : "caret-down"}
                />
              </td>
            </tr>
            {state[index] ? (
              <>
                <tr className="title-list-article">
                  <td>Nom</td>
                  <td>Quantité</td>
                  <td>Parfum</td>
                  <td>Stock</td>
                </tr>
                {order.products.map((article) => (
                  <tr key={article._id} className="list-article-details">
                    <td>{article.product.name}</td>
                    <td>{article.quantity}</td>
                    <td>{article.perfume}</td>
                    <td>{article.product.stock}</td>
                  </tr>
                ))}
                <tr className="title-list-price">
                  <td>Prix total</td>
                  <td>Frais divers</td>
                  <td>Total payé</td>
                </tr>
                <tr className="list-order-price">
                  <td>{intlFormat(order.price)}</td>
                  <td>
                    {intlFormat(order.fees.transport + order.fees.transaction)}
                  </td>
                  <td>
                    {intlFormat(
                      order.price +
                        order.fees.transport +
                        order.fees.transaction
                    )}
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        ))}
      </table>
    );
  };

  return isLoading ? (
    <div className="loading">
      <Loader type="Circles" width={60} color="#618F92" />
    </div>
  ) : (
    <div className="backoffice-order-container">
      <h2>Choisir un type de commande</h2>
      <div className="scroll-type-status">
        <ScrollMenu
          options={[
            { name: "Commandes en cours", value: 0 },
            { name: "Commandes terminées", value: 1 },
          ]}
          initialValue={orderList}
          onChange={({ value }) => setOrderList(value)}
        />
      </div>

      {orderList === 0 ? (
        <OrderTableList
          data={data.filter((order) => order.status !== 2)}
          title="Liste des commandes en cours"
          state={inOrderList}
          setState={setInOrderList}
        />
      ) : orderList === 1 ? (
        <OrderTableList
          data={data.filter((order) => order.status === 2)}
          title="Liste des commandes terminées"
          state={oldOrderList}
          setState={setOldOrderList}
        />
      ) : null}
    </div>
  );
};

export default OrderStatus;
