import "./StarVote.css";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StarVote = (props) => {
  const [result, setResult] = useState(0);

  const ratingStar = (num) => {
    if (typeof num === "string" || (!num && num !== 0)) {
      return "Please provide a number.";
    }

    let rating = [];
    const isDecimal = !Number.isInteger(num);
    const flooredNum = Math.floor(num);
    const decimal = Math.round(num - flooredNum);
    for (let i = 1; i <= 5; i++) {
      if (num >= i) {
        rating.push(<FontAwesomeIcon key={rating.length} icon="star" />);
      }

      if (num < i && rating.length < 5) {
        rating.push(
          <FontAwesomeIcon key={rating.length} icon="fa-regular fa-star" />
        );
      }

      if (flooredNum === i && isDecimal && decimal > 0) {
        rating.push(<FontAwesomeIcon key={rating.length} icon="star-half" />);
      }
    }
    return rating;
  };

  useEffect(() => {
    if (props.average) {
      return;
    }
    const star1 = document.getElementById("star-1");
    const star2 = document.getElementById("star-2");
    const star3 = document.getElementById("star-3");
    const star4 = document.getElementById("star-4");
    const star5 = document.getElementById("star-5");
    const starArr = [star1, star2, star3, star4, star5];
    const changeColorHover = (event) => {
      const index = starArr.indexOf(event.fromElement);
      starArr.forEach((e, i) =>
        i <= index
          ? e.classList.add("colorHover")
          : e.classList.remove("colorHover")
      );
    };
    const colorChangeTrue = (event) => {
      const element = event.path[1];
      const index = starArr.indexOf(element);
      index !== -1 &&
        starArr.forEach((e, i) => {
          if (index === 0) {
            if (
              Array.from(element.classList).filter((e) => e === "activeColor")
                .length !== 0
            ) {
              e.classList.remove("activeColor");
            } else {
              i === 0
                ? e.classList.add("activeColor")
                : e.classList.remove("activeColor");
            }
          } else {
            i <= index
              ? e.classList.add("activeColor")
              : e.classList.remove("activeColor");
          }
        });
    };
    document.addEventListener("mouseover", changeColorHover);
    document.addEventListener("mousedown", colorChangeTrue);
    return () => {
      document.removeEventListener("mouseover", changeColorHover);
      document.removeEventListener("mousedown", colorChangeTrue);
    };
  }, [props.average]);

  const handleChangeResult = (value) => {
    if (value === 1) {
      result !== 0 ? setResult(0) : setResult(value);
    } else {
      setResult(value);
    }
  };
  const submitResult = () => {
    props.submitting(result);
  };
  return (
    <div className={props.className || ""}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: "10px",
          borderRadius: "10px",
        }}
        className="stars-container"
      >
        {!props.average ? (
          <>
            <FontAwesomeIcon
              className="icon-star-vote"
              onClick={() => handleChangeResult(1)}
              id="star-1"
              icon="star"
            />
            <FontAwesomeIcon
              className="icon-star-vote"
              onClick={() => handleChangeResult(2)}
              id="star-2"
              icon="star"
            />
            <FontAwesomeIcon
              className="icon-star-vote"
              onClick={() => handleChangeResult(3)}
              id="star-3"
              icon="star"
            />
            <FontAwesomeIcon
              className="icon-star-vote"
              onClick={() => handleChangeResult(4)}
              id="star-4"
              icon="star"
            />
            <FontAwesomeIcon
              className="icon-star-vote"
              onClick={() => handleChangeResult(5)}
              id="star-5"
              icon="star"
            />
          </>
        ) : (
          ratingStar(props.average)
        )}
      </div>
      {!props.average && (
        <button className="btn-star-vote" type="submit" onClick={submitResult}>
          Enregistrez
        </button>
      )}
    </div>
  );
};

export default StarVote;
