import { useEffect, useState } from "react";
import "./SearchBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollMenu from "../ScrollMenu/ScrollMenu";
import { sortObj } from "../../helper/helper";
import RangeBar from "../RangeBar/RangeBar";
import ToggleCheck from "../ToggleCheck/ToggleCheck";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const SearchBar = ({ dataConfig }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [search, setSearch] = useState("");
  const [values, setValues] = useState({
    title: "",
    category: "",
    sort: "asc",
    range: [0, 100],
  });

  const handleChangeSearch = (event) => {
    const valuesObj = { ...values };
    valuesObj.title = event.target.value;
    setValues(valuesObj);
    return setSearch(event.target.value);
  };
  const handleChangeCategory = (option) => {
    const valuesObj = { ...values };
    valuesObj.category = option.value;
    setValues(valuesObj);
  };
  const handleChangeSort = (value) => {
    const valuesObj = { ...values };
    if (!value) {
      valuesObj.sort = "asc";
    } else {
      valuesObj.sort = "desc";
    }
    setValues(valuesObj);
  };
  const handleChangeRange = (arr) => {
    const valuesObj = { ...values };
    valuesObj.range = arr;
    setValues(valuesObj);
  };

  const arrCategories = [];
  dataConfig.categories.forEach((category) => {
    return category.value === ""
      ? arrCategories.push({ name: "Toutes", value: "" })
      : category.value === "new"
      ? null
      : arrCategories.push({ name: category.key, value: category.value });
  });

  useEffect(() => {
    if (location.pathname !== "/search") {
      return;
    }
    const fetchSearch = async (values) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/product?name=${values.title}&category=${values.category}&priceMin=${values.range[0]}&priceMax=${values.range[1]}&sort=${values.sort}`
        );
        navigate("/search", { state: { result: response.data } });
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchSearch(values);
  }, [values, navigate, location.pathname]);

  const onSubmitSearch = async (values) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/product?name=${values.title}&category=${values.category}&priceMin=${values.range[0]}&priceMax=${values.range[1]}&sort=${values.sort}`
      );
      navigate("/search", { state: { result: response.data } });
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const searchIcon = document.querySelector(".search-icon-bar");
    const iconArrow = document.querySelector(".search-icon-arrow");
    const searchBar = document.querySelector(".search-bar-container");
    const closeClass = "close-search-container";

    const transitionSearchBar = (event) => {
      if (iconArrow.contains(event.target)) {
        searchBar.classList.add(closeClass);
        searchBar.classList.add("overflow-hidden");
        setTimeout(() => {
          searchBar.classList.add("hidden");
          searchIcon.classList.remove("hidden");
        }, 1000);
      } else if (searchIcon.contains(event.target)) {
        searchBar.classList.add("overflow-hidden");
        searchIcon.classList.add("hidden");
        searchBar.classList.remove(`hidden`);
        setTimeout(() => searchBar.classList.remove(closeClass), 10);
      }
    };

    setTimeout(() => searchIcon.click(), 1000);

    document.addEventListener("click", transitionSearchBar);
    searchBar.addEventListener("transitionend", () =>
      searchBar.classList.remove("overflow-hidden")
    );
    return () => {
      document.removeEventListener("click", transitionSearchBar);
    };
  }, []);

  return (
    <div className="container search-container">
      <div className="search-bar-container close-search-container hidden">
        <label htmlFor="searchBar" className="search-label">
          <span>Recherchez :</span>
          <input
            className="search-input"
            type="text"
            name="searchBar"
            value={search}
            onChange={handleChangeSearch}
          />
          <FontAwesomeIcon
            onClick={() => onSubmitSearch(values)}
            className="icon-search-bar"
            icon="search"
          />
        </label>
        <div className="search-category">
          <span>Trier par :</span>
          <ScrollMenu
            options={sortObj(arrCategories, "value")}
            label="Catégories"
            arrow={true}
            onChange={handleChangeCategory}
          />
        </div>
        <div className="search-sort">
          <span>Ordre de prix :</span>
          <ToggleCheck funcValue={handleChangeSort} />
        </div>
        <div className="search-range">
          <span>Prix :</span>
          <RangeBar funcValues={handleChangeRange} />
        </div>
        <div className="search-icon-arrow">
          <FontAwesomeIcon id="icon-arrow" icon="caret-right" />
        </div>
      </div>
      <div className="search-icon-bar">
        <FontAwesomeIcon icon="search" />
      </div>
    </div>
  );
};

export default SearchBar;
