import "./UpDateAdress.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useAlert } from "react-alert";
import AdressForm from "../AdressForm/AdressForm";

const UpDateAdress = ({ userData, token, handleChangeData }) => {
  const alert = useAlert();

  const [isOpenModal, setIsOpenModal] = useState([false, "", null, null]);

  const AdressDelivery = ({ data }) => {
    const deleteAdress = async () => {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_URL}/user/profile/delete`,
          {
            headers: {
              authorization: "Bearer " + token,
            },
            params: { delivery: data._id },
          }
        );
        alert.show(response.data.message, { type: "success" });
        handleChangeData();
      } catch (error) {
        console.log(error.message);
      }
    };
    return (
      <div className="profile-adress-delivery">
        <p>{`${data.lastName ? data.lastName : ""} ${data.firstName}`}</p>
        <div className="adress-info-container">
          <div className="adress-info">
            <span>{data.adress.road}</span>
            <span>{data.adress.postalCode}</span>
            <span>{data.adress.city}</span>
            {data.phone && <span>Tel : 0{data.phone}</span>}
            {data.relay && (
              <span style={{ color: "#618f92", fontSize: "16px" }}>
                Point mondial-relay
              </span>
            )}
          </div>
          <div className="adress-icon-info">
            <FontAwesomeIcon
              onClick={() =>
                openModal(
                  "Modifier cette adresse",
                  onSubmitAdress,
                  data,
                  "deliveryUpdate"
                )
              }
              className="profile-icon-handle-adress"
              icon="pencil"
            />
            <FontAwesomeIcon
              onClick={deleteAdress}
              className="profile-icon-handle-adress"
              icon="trash"
            />
          </div>
        </div>
      </div>
    );
  };
  /* Modal */

  const userAdressBillingObj = {};

  userData.firstName && (userAdressBillingObj.firstName = userData.firstName);
  userData.lastName && (userAdressBillingObj.lastName = userData.lastName);
  userData.phone && (userAdressBillingObj.phone = userData.phone);
  userData.adress && (userAdressBillingObj.adress = userData.adress);

  const closeModal = () => {
    setIsOpenModal([false, "", null, null]);
    document.body.style.overflow = "auto";
  };

  const openModal = (name, onSubmit, data, type) => {
    setIsOpenModal([true, name, onSubmit, data, type]);
    document.body.style.overflow = "hidden";
  };
  const onSubmitAdress = async (values, type, id) => {
    try {
      let data = {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        adress: {
          road: values.adress,
          postalCode: values.postalCode,
          city: values.city,
        },
      };
      id && (data.id = id);
      if (type === "delivery") {
        data = { delivery: { ...data } };
      }
      if (type === "deliveryUpdate") {
        data = { deliveryUpdate: { ...data } };
      }

      const response = await axios.put(
        `${process.env.REACT_APP_URL}/user/profile/update`,
        data,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );
      alert.show(response.data.message, { type: "success" });
      handleChangeData();
      closeModal();
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <AdressForm
        data={isOpenModal[3]}
        onSubmit={isOpenModal[2]}
        onClose={closeModal}
        title={isOpenModal[1]}
        isOpenModal={isOpenModal[0]}
        closeModal={closeModal}
        type={isOpenModal[4]}
      />
      <div className="profile-adress">
        <h2>Mes adresses</h2>
        <section className="profile-billing">
          <div className="title-profile-billing">
            <FontAwesomeIcon
              className="profile-icon-title"
              icon="fa-regular fa-envelope"
            />
            <h3>Adresse de facturation</h3>
          </div>
          {userData.adress ? (
            <div className="profile-adress-billing">
              <p>{`${userData.lastName} ${userData.firstName}`}</p>
              <div className="adress-info-container">
                <div className="adress-info">
                  <span>{userData.adress.road}</span>
                  <span>{userData.adress.postalCode}</span>
                  <span>{userData.adress.city}</span>
                  <span>Tel : 0{userData.phone}</span>
                </div>
                <div className="adress-icon-info">
                  <FontAwesomeIcon
                    className="profile-icon-handle-adress"
                    icon="pencil"
                    onClick={() =>
                      openModal(
                        "Modifier cette adresse",
                        onSubmitAdress,
                        userAdressBillingObj,
                        "billing"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="btn-container-billing">
              <button
                onClick={() =>
                  openModal(
                    "Adresse de facturation",
                    onSubmitAdress,
                    userAdressBillingObj,
                    "billing"
                  )
                }
              >
                Entrez une adresse de facturation
              </button>
            </div>
          )}
        </section>
        <section className="profile-delivery-container">
          <div className="title-profile-adress-delivery">
            <div className="title-profile-delivery">
              <FontAwesomeIcon className="profile-icon-title" icon="box" />
              <h3>Adresses de livraison enregistrées</h3>
            </div>
            <button
              onClick={() =>
                openModal(
                  "Adresse de livraison",
                  onSubmitAdress,
                  null,
                  "delivery"
                )
              }
            >
              Ajoutez une adresse
            </button>
          </div>
          {userData.delivery && userData.delivery.length !== 0 ? (
            userData.delivery.map((adress) => (
              <AdressDelivery key={adress._id} data={adress} />
            ))
          ) : (
            <div className="profile-no-delivery">
              Vous n'avez aucune autre adresse de livraison. 🙂
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default UpDateAdress;
