import { useState, useCallback } from "react";
import "./FormikInputs.css";
import { useField } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";
import { sortObj, capitalizeFirstLettre } from "../../helper/helper";

const InputTextProject = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label className={props.classnamelabel} htmlFor={props.id || props.name}>
        {label}
      </label>
      {props.icon === "true" ? (
        <div className="checked-user">
          <input
            className={props.classnameinput}
            type={props.type || "text"}
            autoFocus={props.autoFocus}
            {...field}
            {...props}
          />
          {props.validateduser === "true" ? (
            <FontAwesomeIcon className="icon-check" icon="check" />
          ) : null}
        </div>
      ) : (
        <input
          className={props.classnameinput}
          type={props.type || "text"}
          {...field}
          {...props}
        />
      )}

      {meta.touched && meta.error ? (
        <div className={props.classnameerror || "error"}>{meta.error}</div>
      ) : null}
    </>
  );
};

const InputPasswordProject = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [typePassword, setTypePassword] = useState("password");

  const handleChangeType = () =>
    typePassword === "password"
      ? setTypePassword("text")
      : setTypePassword("password");

  return (
    <>
      <label className={props.classnamelabel} htmlFor={props.id || props.name}>
        {label}
      </label>
      <div className="password">
        <input
          className={props.classnameinput}
          type={typePassword}
          {...field}
          {...props}
        />
        <FontAwesomeIcon
          onClick={handleChangeType}
          className="icon-form"
          icon={typePassword === "password" ? "eye" : "eye-slash"}
        />
      </div>

      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

const MyDropZone = ({ label, setFieldValue, ...props }) => {
  const [files, setFiles] = useState([]);
  const [previousFiles, setPreviousFiles] = useState([]);
  const maxFiles = props.max || 2;
  const onDrop = useCallback(
    (acceptedFiles) => {
      // files here

      if (files.length < maxFiles) {
        setFiles([...files, acceptedFiles[0]]);
        setFieldValue("files", [...files, acceptedFiles[0]]);
        setPreviousFiles([
          ...previousFiles,
          URL.createObjectURL(acceptedFiles[0]),
        ]);
      }
    },
    [setFieldValue, previousFiles, files, setFiles, maxFiles]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    maxFiles: maxFiles,
    onDrop,
  });

  const handleDeleteImage = (index) => {
    const arrPrevious = previousFiles.filter(
      (img) => img !== previousFiles[index]
    );
    const arrFiles = files.filter((img) => img !== files[index]);
    setPreviousFiles(arrPrevious);
    setFiles(arrFiles);
    setFieldValue("files", arrFiles.length === 0 ? null : arrFiles);
  };

  return (
    <>
      <label
        className={props.classnamelabel || "label-modal"}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <div {...getRootProps({ className: "myDropzone" })}>
        <input {...getInputProps()} />
        {!props.minus && !props.both ? (
          files.length === 0 && isDragActive ? (
            <div className="dropzone-text">déposez votre image ici </div>
          ) : files.length === 0 && !isDragActive ? (
            <div className="dropzone-text">
              Cliquez ou faites gilsser une image
            </div>
          ) : null
        ) : !props.both && files.length === 0 && isDragActive ? (
          <FontAwesomeIcon className="dropzone-icon" icon="hand-point-down" />
        ) : !props.both && files.length === 0 && !isDragActive ? (
          <FontAwesomeIcon className="dropzone-icon" icon="image-portrait" />
        ) : props.both ? (
          files.length === 0 && isDragActive ? (
            <>
              <div className="dropzone-text">déposez votre image ici </div>
              <FontAwesomeIcon
                className="dropzone-icon"
                icon="hand-point-down"
              />
            </>
          ) : files.length === 0 && !isDragActive ? (
            <>
              <div className="dropzone-text">
                Cliquez ou faites gilsser une image
              </div>
              <FontAwesomeIcon
                className="dropzone-icon"
                icon="image-portrait"
              />
            </>
          ) : null
        ) : null}
        {previousFiles &&
          previousFiles.map((img, index) => (
            <div key={`img${index}`} className="previous-img">
              <button
                className="btn btn-img"
                onClick={() => handleDeleteImage(index)}
              >
                X
              </button>
              <img src={img} alt="" />
            </div>
          ))}
      </div>
    </>
  );
};

const InputSelectProject = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const [inputControl, setInputControl] = useState("");
  const [options, setOptions] = useState(sortObj(props.categories, "value"));
  const [errorCategory, setErrorCategory] = useState("");

  const handleChangeCategory = (event) => {
    event.target.value !== ""
      ? setInputControl(capitalizeFirstLettre(event.target.value))
      : setInputControl("");
    const existingCategory = props.categories.filter(
      (elem) => elem.key === event.target.value
    );
    if (existingCategory.length !== 0) {
      setErrorCategory("Cette catégorie existe déjà");
    } else {
      setErrorCategory("");
    }
  };

  const handleSubmit = () => {
    const existingCategory = props.categories.filter(
      (elem) => elem.key === inputControl
    );
    if (inputControl.trim() !== "" && existingCategory.length === 0) {
      const newArrCategories = [
        ...props.categories,
        { key: inputControl, value: inputControl },
      ];
      props.newCategory(newArrCategories, inputControl);
      setOptions(sortObj(newArrCategories, "value"));
      setInputControl("");
    }
  };
  return (
    <>
      <label
        className={props.classnamelabel || "label-modal"}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <div className="input-select">
        <select
          id={props.className || "select"}
          className={
            props.className
              ? `input--select input-modal ${props.className}`
              : "input--select input-modal"
          }
          name={props.name}
          {...field}
        >
          {options.map((category) => {
            return (
              <option
                key={category.value}
                value={category.value}
                label={category.key}
              />
            );
          })}
        </select>
        {field.value === "new" ? (
          <>
            <div className="select-container">
              <input
                name="newProduct"
                type="text"
                className="input-modal"
                onChange={handleChangeCategory}
                value={inputControl}
              />
              <div className="btn-validate-category" onClick={handleSubmit}>
                Validez
              </div>
            </div>
            <div className="error">{errorCategory}</div>
          </>
        ) : null}
      </div>
      {meta.touched && meta.error ? (
        <div className={props.classnameerror || "error"}>{meta.error}</div>
      ) : null}
    </>
  );
};

const InputAreaProject = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <label className={props.classnamelabel} htmlFor={props.id || props.name}>
        {label}
      </label>
      <textarea
        className={props.classnamearea}
        {...field}
        {...props}
      ></textarea>

      {meta.touched && meta.error ? (
        <div className={props.classnameerror || "error"}>{meta.error}</div>
      ) : null}
    </>
  );
};

const CheckBoxProject = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label className={props.classnamelabel} htmlFor={props.id || props.name}>
        <input type="checkbox" {...field} {...props} />
        {label}
      </label>

      {meta.touched && meta.error ? (
        <div className={props.classnameerror || "error"}>{meta.error}</div>
      ) : null}
    </>
  );
};
export {
  InputTextProject,
  InputPasswordProject,
  MyDropZone,
  InputSelectProject,
  InputAreaProject,
  CheckBoxProject,
};
