import "../assets/css/UpDateProfile.css";
import { useState, useEffect } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalizeFirstLettre } from "../helper/helper";
import UpDateContactInformation from "../components/Profile/UpDateContactInformation";
import UpdateAvatar from "../components/Profile/UpdateAvatar";
import UpDateAdress from "../components/Profile/UpDateAdress";
import UpDateOrder from "../components/Profile/UpDateOrder";

const UpDateProfile = ({ token, setToken, upDateAvatar, openModal }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [handledData, setHandledData] = useState(false);
  const [changeAvatar, setChangeAvatar] = useState(false);
  const [isOpenElement, setIsOpenElement] = useState([true, false, false]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/user/profile`,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );

        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        if (error.response.data.message) openModal(1);
        console.log(error.response.data.message);
      }
    };
    fetchData();
  }, [token, handledData, openModal]);

  const handleChangeAvatar = () => {
    setChangeAvatar(!changeAvatar);
  };
  const handleChangeData = () => {
    setHandledData(!handledData);
  };
  const handleChangeElement = (num) => {
    setIsOpenElement(
      isOpenElement.map((elem, index) => (num === index ? true : false))
    );
  };

  return isLoading ? (
    <div className="loading">
      <Loader type="Circles" width={60} color="#618F92" />
    </div>
  ) : (
    <div className="pages profile-container container">
      <div className="side-bar-profile">
        {!changeAvatar ? (
          <div className="wellcome">
            <div className="avatar-container">
              {data.account.avatar ? (
                <img src={data.account.avatar} alt="avatar" />
              ) : (
                <div className="no-avatar-profile">
                  {data.account.userName[0].toUpperCase()}
                </div>
              )}
              <div onClick={handleChangeAvatar} className="icon-avatar-profile">
                <FontAwesomeIcon className="icon-profile" icon="user-pen" />
                <span>Modifiez l'avatar</span>
              </div>
            </div>

            <div className="user-hello">
              <p>Bonjour</p>
              <p>{capitalizeFirstLettre(data.account.userName)}</p>
            </div>
          </div>
        ) : (
          <UpdateAvatar
            name="avatar"
            onClose={handleChangeAvatar}
            token={token}
            upDateAvatar={upDateAvatar}
            handleChangeData={handleChangeData}
          />
        )}
        <nav className="nav-bar-profile">
          <div
            onClick={() => handleChangeElement(0)}
            className="information-profile"
          >
            Mes informations
          </div>
          <div
            onClick={() => handleChangeElement(1)}
            className="information-profile"
          >
            Mes adresses
          </div>
          <div
            onClick={() => handleChangeElement(2)}
            className="information-profile"
          >
            Mes commandes
          </div>
        </nav>
      </div>

      <div className="update-profile-container">
        {isOpenElement[0] ? (
          <UpDateContactInformation
            dataUser={data}
            token={token}
            setToken={setToken}
            handleChangeData={handleChangeData}
          />
        ) : isOpenElement[1] ? (
          <UpDateAdress
            userData={data}
            token={token}
            handleChangeData={handleChangeData}
          />
        ) : (
          isOpenElement[2] && <UpDateOrder token={token} />
        )}
      </div>
    </div>
  );
};

export default UpDateProfile;
