import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState, useRef } from "react";
import "./ScrollMenu.css";

/* Props options is an array of object {name:"toto",value:0}, onChange function use for do what you want */
const ScrollMenu = ({ label, novalue, options, onChange, arrow, ...props }) => {
  const scrollRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState();

  /* function click on scrolling menu */
  const handleClickOutSide = (event) => {
    if (scrollRef.current.contains(event.target) || event.type === "scroll") {
      return;
    }
    setIsOpen(false);
  };

  // Click on change option
  const handleChange = (option) => {
    if (onChange) {
      onChange(option);
    }
    setValue(option.name);
    setIsOpen(false);
    return;
  };

  /* useEffect for event listener */
  useEffect(() => {
    if (!options) {
      return;
    }
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, [options]);

  useEffect(() => {
    if (!props.initialValue && props.initialValue !== 0) {
      return;
    }
    const currentValue = options.filter(
      (option) => option.value === props.initialValue
    );
    if (onChange) {
      onChange(...currentValue);
    }
    setValue(currentValue[0].name);
  }, [props.initialValue, options, onChange]);

  return (
    <div ref={scrollRef} className={props.classContainer || "scroll-container"}>
      {props.bars && !isOpen ? (
        <FontAwesomeIcon
          onClick={() => setIsOpen(true)}
          icon="bars"
          className="nav-bar-icon"
        />
      ) : !isOpen && novalue ? null : (
        <div
          className={
            props.classButton ||
            (isOpen ? "scroll-btn-open" : "scroll-btn-close")
          }
          onClick={() => setIsOpen(!isOpen)}
        >
          {value || label || "List Name"}
          {arrow ? (
            <FontAwesomeIcon
              className={isOpen ? "icon-scroll-hidden" : "icon-scroll"}
              icon="caret-down"
            />
          ) : null}
          {arrow ? (
            <FontAwesomeIcon
              className={isOpen ? "icon-scroll" : "icon-scroll-hidden"}
              icon="caret-up"
            />
          ) : null}
        </div>
      )}
      {isOpen && (
        <ul className={props.classUl || "scroll-ul"}>
          {novalue && (
            <li
              className={props.classLi || "scroll-li no-value"}
              onClick={() => handleChange(undefined)}
            >
              {novalue}
            </li>
          )}
          {options && options.lenght !== 0
            ? options.map((option, index) => {
                return (
                  <li
                    className={props.classLi || "scroll-li"}
                    key={index}
                    onClick={() => handleChange(option)}
                  >
                    {option.name}
                  </li>
                );
              })
            : null}
        </ul>
      )}
    </div>
  );
};

export default ScrollMenu;
