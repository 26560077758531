import { useEffect } from "react";
import { useScript } from "../../helper/helper";

const WidgetMondial = () => {
  const statusWidget = useScript(
    "https://widget.mondialrelay.com/parcelshop-picker/jquery.plugin.mondialrelay.parcelshoppicker.min.js"
  );

  const jqueryFunc = () => {
    const statusJqueryFunction = document.createElement("script");
    statusJqueryFunction.async = true;
    statusJqueryFunction.type = "text/javascript";
    statusJqueryFunction.text = `!function(){$(document).ready(function () {$("#Zone_Widget").MR_ParcelShopPicker({Target: "#ParcelShopCode",Brand: "${process.env.REACT_APP_MONDIAL_RELAY_KEY}",Country: "FR",Responsive: true ,OnParcelShopSelected: (data) => document.querySelector("#selectedAdress").value = JSON.stringify(data)});});}();`;
    document.body.appendChild(statusJqueryFunction);
    return;
  };
  useEffect(() => {
    if (statusWidget !== "ready") {
      return;
    }
    setTimeout(() => jqueryFunc(), 300);
  }, [statusWidget]);

  return (
    <div>
      <div id="Zone_Widget" />
    </div>
  );
};

export default WidgetMondial;
