import "../assets/css/Product.css";
import { useState, useEffect } from "react";
import ProductElement from "../components/Product/ProductElement";
import axios from "axios";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Product = ({ dataConfig, token, openModal }) => {
  const [dataProduct, setDataProduct] = useState();
  const [isLoading, setIsLoading] = useState(true);

  /* Scroll arrows */
  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/product?ordered=true`
        );
        const promotionArr = await axios.get(
          `${process.env.REACT_APP_URL}/promotion?articles=true`
        );
        const categoriesKeys = Object.keys(response.data.products);
        const productList = categoriesKeys.map((category) => (
          <section key={category} className="product-categories">
            <h2>{category}</h2>
            <FontAwesomeIcon
              className="icon-info-product-left"
              icon="arrow-alt-circle-left"
              onClick={() => {
                const container = document.getElementById(`${category}`);
                sideScroll(container, "left", 30, 616, 8);
              }}
            />
            <div id={category} className="product-list">
              {response.data.products[category].map((product) => {
                promotionArr.status === 200 &&
                  promotionArr.data.forEach((promo) =>
                    product._id === promo.product._id
                      ? (product.reduction = promo.reduction)
                      : null
                  );
                return (
                  <ProductElement
                    key={product._id}
                    data={product}
                    dataConfig={dataConfig}
                    token={token}
                    openModal={openModal}
                    reduction={product.reduction ? true : false}
                  />
                );
              })}
            </div>
            <FontAwesomeIcon
              className="icon-info-product-right"
              icon="arrow-alt-circle-right"
              onClick={() => {
                const container = document.getElementById(`${category}`);
                sideScroll(container, "right", 30, 616, 8);
              }}
            />
          </section>
        ));
        setDataProduct(productList);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchProducts();
  }, [dataConfig, token, openModal]);

  return isLoading ? (
    <div className="pages loading">
      <Loader type="Circles" color="#618f92" height={60} width={60} />
    </div>
  ) : (
    <div className="container">{dataProduct}</div>
  );
};

export default Product;
