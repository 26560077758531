import { MyDropZone } from "../../components/FormikInputs/FormikInputs";
import { Formik, Form } from "formik";
import Loader from "react-loader-spinner";
import axios from "axios";
import { useAlert } from "react-alert";

const HomePictureUpdate = ({ token }) => {
  const alert = useAlert();
  return (
    <Formik
      initialValues={{ files: null }}
      onSubmit={async (values, { setSubmitting }) => {
        const formData = new FormData();
        formData.append("files", values.files[0]);
        const response = await axios.put(
          `${process.env.REACT_APP_URL}/backoffice/home_picture`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: "Bearer " + token,
            },
          }
        );
        if (response.status === 200) {
          setSubmitting(false);
          alert.show(response.data.message, { type: "success" });
        }
      }}
    >
      {({ setFieldValue, isSubmitting }) => (
        <Form className="handle-home-picture">
          <MyDropZone
            label="Nouvelle image d'accueil :"
            setFieldValue={setFieldValue}
            name="files"
            max={1}
          />
          {isSubmitting ? (
            <Loader
              className="loader"
              type="Circles"
              color="#618f92"
              height={60}
              width={60}
            />
          ) : (
            <button className="btn btn_modal" type="submit">
              Validez
            </button>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default HomePictureUpdate;
