import "./AdressForm.css";
import Modal from "react-modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "yup-phone-lite";
import { InputTextProject } from "../FormikInputs/FormikInputs";

const AdressForm = ({
  title,
  onClose,
  onSubmit,
  isOpenModal,
  closeModal,
  data,
  type,
}) => {
  /* Validation form */
  const validationYup = Yup.object().shape({
    firstName: Yup.string().required("Obligatoire"),
    lastName: Yup.string().required("Obligatoire"),
    adress: Yup.string().required("Obligatoire"),
    postalCode: Yup.number().required("Obligatoire"),
    city: Yup.string().required("Obligatoire"),
    phone: Yup.string()
      .phone("FR", "numéro non valide")
      .required("Obligatoire"),
  });
  /* Style modal */
  const customStyles = {
    content: {
      top: "60%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderColor: "#456b76",
      borderRadius: "10px",
    },
  };

  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={isOpenModal}
      style={customStyles}
      onRequestClose={closeModal}
      id="modal-adress-form"
    >
      <Formik
        initialValues={{
          firstName: data && data.firstName ? data.firstName : "",
          lastName: data && data.lastName ? data.lastName : "",
          adress:
            data && data.adress && data.adress.road ? data.adress.road : "",
          postalCode:
            data && data.adress && data.adress.postalCode
              ? data.adress.postalCode
              : "",
          city: data && data.adress && data.adress.city ? data.adress.city : "",
          phone: data && data.phone ? "0" + data.phone : "",
        }}
        enableReinitialize
        onSubmit={(values) => onSubmit(values, type, data && data._id)}
        validationSchema={validationYup}
      >
        {() => (
          <Form id="order-form">
            <h3>{title}</h3>

            <InputTextProject placeholder="Nom" name="lastName" />
            <InputTextProject placeholder="Prénom" name="firstName" />
            <InputTextProject placeholder="Adresse" name="adress" />
            <InputTextProject placeholder="Code postal" name="postalCode" />
            <InputTextProject placeholder="Ville" name="city" />
            <InputTextProject placeholder="Numéro de téléphone" name="phone" />

            <div className="btn-adress-container">
              <button type="submit" className="btn-adress-form-1">
                Validez
              </button>
              <button onClick={onClose} className="btn-adress-form-2">
                Annulez
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AdressForm;
