import { useState } from "react";
import "./ToggleCheck.css";

const ToggleCheck = ({ funcValue }) => {
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const handleChangeToggleSwitch = () => {
    funcValue && funcValue(!toggleSwitch);
    return setToggleSwitch(!toggleSwitch);
  };
  return (
    <label className="switch" htmlFor="switch">
      <input
        id="switch"
        checked={toggleSwitch}
        onChange={handleChangeToggleSwitch}
        type="checkbox"
      />
      <div className="slider"></div>
    </label>
  );
};

export default ToggleCheck;
