import FormContact from "../components/FormContact/FormContact";
import MapWrapper from "../components/Map/MapWrapper";

const Contact = ({ userInfo }) => {
  return (
    <div className="pages">
      <div className="adress-container-map">
        <p className="adress-map">
          <strong>Emplacement du site : </strong>
          <br /> Lieu dit Le Trieux
          <br /> 24360 Bussière-badil
          <br /> Nouvelle Aquitaine
        </p>
      </div>
      <MapWrapper />
      <FormContact userInfo={userInfo} />
    </div>
  );
};

export default Contact;
