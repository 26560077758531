import "../assets/css/SearchResult.css";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";
import ProductElement from "../components/Product/ProductElement";

const SearchResult = ({ openModal, token, dataConfig }) => {
  const location = useLocation();

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const productsArr = location.state.result.products;

  useEffect(() => {
    (async () => {
      try {
        if (productsArr.length === 0) {
          return;
        }
        const responsePromotions = await axios.get(
          `${process.env.REACT_APP_URL}/promotion?articles=true`
        );
        const updatedData = productsArr.map((product) => {
          responsePromotions.status === 200 &&
            responsePromotions.data.forEach((promo) =>
              promo.product._id === product._id
                ? (product.reduction = promo.reduction)
                : null
            );
          return {
            ...product.user,
            _id: product._id,
            reduction: product.reduction || false,
          };
        });

        setData(updatedData);

        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, [productsArr]);

  return isLoading ? (
    <div className="loading">
      <Loader type="Circles" width={60} color="#456B76" />
    </div>
  ) : (
    <div className="search-result container">
      {data.map((product) => (
        <ProductElement
          key={product._id}
          data={product}
          reduction={product.reduction}
          openModal={openModal}
          token={token}
          dataConfig={dataConfig}
        />
      ))}
    </div>
  );
};

export default SearchResult;
