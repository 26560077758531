import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "react-loader-spinner";
import { useAlert } from "react-alert";
import { intlFormat } from "../../helper/helper";
import axios from "axios";

const OrderValidation = ({ ordered, token }) => {
  const alert = useAlert();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    const fetchOrdered = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/ordered/${ordered.ordered}`,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        alert.show(error.response.data.message);
      }
    };
    fetchOrdered();
  }, [alert, token, ordered]);

  const cancelled = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/ordered/${ordered.ordered}`,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );
      alert.show(response.data.message, { type: "info" });
      navigate("/");
      document.body.style.overflow = "auto";
    } catch (error) {
      alert.show(error.response.data.message, { type: "error" });
    }
  };

  const validated = async () => {
    try {
      setIsSubmitting(true);
      const validatedOrdered = await axios.put(
        `${process.env.REACT_APP_URL}/ordered/${ordered.ordered}`,
        { validated: true },
        { headers: { authorization: "Bearer " + token } }
      );
      if (validatedOrdered) {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/payment`,
          {
            token: ordered.stripeToken.token,
            ordered: ordered.ordered,
          },
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );
        alert.show(response.data.message, { type: "success" });
        setIsSubmitting(false);
        navigate("/");
      }
    } catch (error) {
      alert.show(error.response.data.message, { type: "error" });
    }
  };

  const AdressContainer = ({ ...props }) => {
    return (
      <div className={props.className}>
        <h3>{props.title}</h3>
        <div>{props.data.lastName}</div>
        <div>{props.data.firstName}</div>
        <p>{props.data.adress.road}</p>
        <p>{props.data.adress.postalCode}</p>
        <p>{props.data.adress.city}</p>
      </div>
    );
  };
  return (
    <div className="container">
      {isLoading ? (
        <div className="loading">
          <Loader type="Circles" width={60} height={60} color="#618F92" />
        </div>
      ) : (
        <div className="billing-container">
          <h2>Récapitulatif de votre commande</h2>
          <AdressContainer
            data={data.billing}
            title="Facturation"
            className="billing"
          />
          <div className="delivery-container">
            <AdressContainer
              data={data.delivery}
              title="Adresse de livraison"
              className="adress-delivery"
            />
          </div>

          <table className="products-ordered">
            <thead>
              <tr>
                <th colSpan="4">Articles commandés</th>
              </tr>
              <tr>
                <td>Référence</td>
                <td>Nom</td>
                <td>Senteur</td>
                <td>Nombre d'article</td>
              </tr>
            </thead>
            <tbody>
              {data.products.map((product) => (
                <tr key={product._id}>
                  <td>{product.product.owner.productRef}</td>
                  <td>{product.product.user.name}</td>
                  <td>{product.perfume}</td>
                  <td>{product.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="delivery-ordered">
            <h3>Transporteur</h3>
            <p>{data.transport}</p>
          </div>
          {data.promotion && data.promotion.description && (
            <div>
              <h3>Promotion</h3>
              <p>{data.promotion.description}</p>
            </div>
          )}
          <div className="payment-method">
            <div>
              <h3>Informations de paiement</h3>
              <h4>Montant de la commade</h4>
              <span>{intlFormat(ordered.price)}</span>
              {ordered.stripeToken.token.card.name !== null && (
                <>
                  <h4>Titulaire de la carte </h4>
                  <span>{ordered.stripeToken.token.card.name}</span>
                </>
              )}
              <div className="card-payment-info">
                <h4>Informations carte</h4>
                <span>Type :{ordered.stripeToken.token.card.brand}</span>
                <span>
                  N°*************{ordered.stripeToken.token.card.last4}
                </span>
                <span>
                  Expire le: {ordered.stripeToken.token.card.exp_month}/
                  {ordered.stripeToken.token.card.exp_year}
                </span>
                <span>Pays :{ordered.stripeToken.token.card.country}</span>
              </div>
            </div>
          </div>
          {isSubmitting ? (
            <div className="loader">
              <Loader type="Circles" width={40} color="#618f92" />
            </div>
          ) : (
            <div className="validation-ordered">
              <button onClick={validated} className="btn">
                Validez
              </button>
              <button onClick={cancelled} className="btn">
                Annulez
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderValidation;
