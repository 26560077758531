import "./RangeBar.css";
import { useState } from "react";
import { Range, getTrackBackground } from "react-range";

const RangeBar = ({ funcValues }) => {
  const [values, setValues] = useState([0, 100]);

  return (
    <Range
      step={5}
      min={0}
      max={100}
      values={values}
      onChange={(values) => setValues(values)}
      onFinalChange={(values) => {
        funcValues(values);
      }}
      renderTrack={({ props, children }) => {
        return (
          <div
            className="track"
            {...props}
            style={{
              background: getTrackBackground({
                values: values,
                colors: ["#F0E1A9", "#85B4AA", "#F0E1A9"],
                min: 0,
                max: 100,
              }),
            }}
          >
            {children}
          </div>
        );
      }}
      renderThumb={({ props }) => (
        <div className="trackThumb" {...props}>
          <div className="trackThumb-price">{values[props.key] + "€"}</div>
        </div>
      )}
    />
  );
};

export default RangeBar;
