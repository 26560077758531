import "./Header.css";
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { capitalizeFirstLettre } from "../../helper/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ScrollMenu from "../ScrollMenu/ScrollMenu";

const Header = ({
  openModal,
  userInfo,
  token,
  setToken,
  admin,
  dataConfig,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [actions, setActions] = useState({});

  const handleActions = (options) => {
    if (typeof options.value === "function") {
      options.value();
    }
  };

  useEffect(() => {
    const regTestUpdate = new RegExp("/product/update/");
    const regTestOrder = new RegExp("/order");
    const regTestProfile = new RegExp("/profile");

    const disconnectUser = () => {
      setToken(null);
      Cookies.remove("afdd_co_to");
      (location.pathname === "/backoffice" ||
        regTestUpdate.test(location.pathname) ||
        regTestOrder.test(location.pathname) ||
        regTestProfile.test(location.pathname)) &&
        navigate("/");
    };

    const actionsScrollMenu = [
      { name: "Accueil", value: () => navigate("/") },
      { name: "Nos Produits", value: () => navigate("/products") },
      { name: "Contactez-nous", value: () => navigate("/contact") },
      { name: "Mon Panier", value: () => navigate("/basket") },
    ];

    if (token) {
      actionsScrollMenu.push(
        { name: "Mon profile", value: () => navigate("/profile/update") },
        {
          name: "Déconnexion",
          value: () => disconnectUser(),
        }
      );
    } else {
      actionsScrollMenu.push(
        { name: "S'inscrire", value: () => openModal(0) },
        { name: "Se connecter", value: () => openModal(1) }
      );
    }
    if (admin) {
      actionsScrollMenu.push({
        name: "BackOffice",
        value: () => navigate("/backoffice"),
      });
    }
    setActions({
      scrollMenu: actionsScrollMenu,
      disconnect: () => disconnectUser(),
    });
  }, [token, navigate, location, setToken, openModal, admin]);

  return (
    <header>
      <div className="header-container">
        <div className="logo">
          <img
            src={dataConfig.logo}
            alt="logo marque"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="site-name" onClick={() => navigate("/")}>
          Au Fil Des Désirs
        </div>
        {!token ? (
          <>
            <nav className="nav-user">
              <button className="btn btn-signup" onClick={() => openModal(0)}>
                S'inscrire
              </button>
              <button className="btn btn-login" onClick={() => openModal(1)}>
                Se connecter
              </button>
            </nav>
            <ScrollMenu
              classContainer="nav-bar-icon-container"
              classUl="nav-bar-ul"
              classLi="nav-bar-li"
              bars={true}
              options={actions.scrollMenu}
              onChange={handleActions}
              className="scroll-menu-header"
            />
          </>
        ) : userInfo === null ? null : (
          <nav className="nav-user-connected">
            <Link to={`/profile/update`}>
              <div className="user-info">
                {userInfo !== null && userInfo.avatar !== null ? (
                  <img
                    className="img-avatar"
                    src={userInfo.avatar}
                    alt="avatar"
                  />
                ) : (
                  <div className="img-avatar">
                    {capitalizeFirstLettre(userInfo.userName)[0]}
                  </div>
                )}
                <p>{capitalizeFirstLettre(userInfo.userName)}</p>
              </div>
            </Link>

            <button onClick={actions.disconnect} className="btn">
              Déconnexion
            </button>
            {admin ? (
              <button
                className="btn btn-backOffice btn-header-connected"
                onClick={() => navigate("/backoffice")}
              >
                Back office
              </button>
            ) : null}
            <ScrollMenu
              classContainer="nav-bar-icon-container"
              classUl="nav-bar-ul"
              classLi="nav-bar-li"
              bars={true}
              options={actions.scrollMenu}
              onChange={handleActions}
              className="scroll-menu-header"
            />
          </nav>
        )}
      </div>
      <nav className="nav-product">
        <Link to="/">
          <FontAwesomeIcon className="icon-nav-header" icon="home" />
          <span>Accueil</span>
        </Link>

        <Link to="/products">
          <FontAwesomeIcon className="icon-nav-header" icon="shopping-bag" />
          <span>Nos Produits</span>
        </Link>
        <Link to="/contact">
          <FontAwesomeIcon className="icon-nav-header" icon="paper-plane" />
          <span>Contactez-nous</span>
        </Link>
        <Link to="/basket">
          <FontAwesomeIcon className="icon-nav-header" icon="shopping-cart" />
          <span>Mon panier</span>
        </Link>
      </nav>
    </header>
  );
};

export default Header;
