import { useState, useEffect } from "react";
import { intlFormat } from "../../helper/helper";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import Loader from "react-loader-spinner";
import { useAlert } from "react-alert";
import { compareAsc, format } from "date-fns";

const OrderPayment = ({
  data,
  workArray,
  userDelivery,
  token,
  handleChangeStep,
  setOrdered,
}) => {
  const alert = useAlert();
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState([true, false, false]);
  const [codePromo, setCodePromo] = useState("");
  const [validatedPromo, setValidatedPromo] = useState();
  const [transactionFees, setTransactionFees] = useState();
  const [subTotal, setSubTotal] = useState();
  const [delivery, setDelivery] = useState();
  const [deliveryFees, setDeliveryFees] = useState();
  const [variousFees, setVariousFees] = useState();
  const [total, setTotal] = useState();

  useEffect(() => {
    const fetchFees = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/backoffice`
        );

        const transactionFees = response.data.fees.transactionFees;
        const subTotal = workArray
          .map((elem) =>
            elem.reduction
              ? (elem.price - (elem.price * elem.reduction) / 100) *
                elem.quantity
              : elem.price * elem.quantity
          )
          .reduce((prev, next) => prev + next);
        const delivery = Object.keys(userDelivery.transport)[0];
        const deliveryFees =
          userDelivery.transport[Object.keys(userDelivery.transport)[0]];
        setTransactionFees(transactionFees);
        setSubTotal(subTotal);
        setDelivery(delivery);
        setDeliveryFees(deliveryFees);
        setVariousFees((subTotal + deliveryFees) * transactionFees);
        setTotal(
          subTotal + deliveryFees + (subTotal + deliveryFees) * transactionFees
        );
        setIsLoading([false, false, false]);
      } catch (error) {
        alert.show(error.response.data.message, { type: "error" });
      }
    };
    fetchFees();
  }, [alert, workArray, userDelivery]);

  /* Promotion build */
  const handleChangeCode = (event) => {
    setCodePromo(event.target.value);
  };

  const validateDate = (promotion) => {
    const today = new Date();
    const dateToday = format(today, "yyyy-MM-dd");

    /* date start return -1 || 0 and date end return 1 || 0 .... code date is valid */
    return compareAsc(new Date(promotion.startDate), new Date(dateToday)) !== 1
      ? compareAsc(new Date(promotion.endDate), new Date(dateToday)) !== -1
      : false;
  };

  const submitCode = async () => {
    try {
      setIsLoading([false, true, false]);
      if (codePromo !== "") {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/promotion`,
          { params: { code: codePromo } }
        );
        if (response.status === 200) {
          const promotion = await response.data;
          if (validateDate(promotion)) {
            switch (promotion.type) {
              case 0:
                // console.log("promo sur article");
                const arrArticle = promotion.article.map((elem) => elem._id);
                const newSubTotal =
                  Math.round(
                    workArray
                      .map(
                        (elem) =>
                          (arrArticle.indexOf(elem.product) !== -1
                            ? elem.price -
                              elem.price * (promotion.reduction / 100)
                            : elem.price) * elem.quantity
                      )
                      .reduce((prev, next) => prev + next) * 100
                  ) / 100;
                const variousFees =
                  (newSubTotal + deliveryFees) * transactionFees;
                setSubTotal(newSubTotal);
                setVariousFees(variousFees);
                setTotal(newSubTotal + deliveryFees + variousFees);
                setValidatedPromo(promotion);
                break;
              case 1:
                // console.log("promo sur panier min");

                if (promotion.minSum <= subTotal) {
                  const newSubTotal =
                    subTotal - subTotal * (promotion.reduction / 100);
                  const newVariousFees =
                    (newSubTotal + deliveryFees) * transactionFees;
                  setSubTotal(newSubTotal);
                  setVariousFees(newVariousFees);
                  setTotal(newSubTotal + newVariousFees + deliveryFees);
                  setValidatedPromo(promotion);
                } else {
                  alert.show(
                    "Le total de vôtre commande est inférieur au minimum de la promotion",
                    { type: "error" }
                  );
                }
                break;
              case 2:
                // console.log("promo sur article le -");
                const littlePrice = workArray
                  .map((elem) => elem.price)
                  .reduce((prev, curr) => (prev < curr ? prev : curr));
                const reduceArticle =
                  Math.round(littlePrice * (promotion.reduction / 100) * 100) /
                  100;
                const totalWithoutReduction = workArray
                  .map((elem) => elem.price * elem.quantity)
                  .reduce((prev, next) => prev + next);
                const totalWithReduction =
                  totalWithoutReduction - reduceArticle;
                const newVariousFees =
                  (totalWithReduction + deliveryFees) * transactionFees;
                setSubTotal(totalWithReduction);
                setVariousFees(newVariousFees);
                setTotal(totalWithReduction + deliveryFees + newVariousFees);
                setValidatedPromo(promotion);
                break;
              case 3:
                // console.log("promo sur panier en €");
                if (promotion.minSum <= subTotal) {
                  const newSubTotal = subTotal - promotion.reduction;
                  const newVariousFees =
                    (newSubTotal + deliveryFees) * transactionFees;
                  setSubTotal(newSubTotal);
                  setVariousFees(newVariousFees);
                  setTotal(newSubTotal + newVariousFees + deliveryFees);
                  setValidatedPromo(promotion);
                } else {
                  alert.show(
                    "Le total de vôtre commande est inférieur au minimum de la promotion",
                    { type: "error" }
                  );
                }
                break;
              default:
                break;
            }
          } else {
            alert.show("Période de promotion non valide", { type: "error" });
            setCodePromo("");
          }
        }
      } else {
        alert.show("Saisir un code promotion", { type: "error" });
      }
      setIsLoading([false, false, false]);
    } catch (error) {
      alert.show(error.response.data.message, { type: "error" });
      setIsLoading([false, false, false]);
    }
  };

  const submitCard = async (event) => {
    event.preventDefault();
    setIsLoading([false, false, true]);

    const cardElement = elements.getElement(CardElement);

    const stripeResponse = await stripe.createToken(cardElement);

    try {
      if (stripeResponse.token) {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/ordered`,
          {
            products: [...workArray],
            delivery: { ...userDelivery },
            promotion: validatedPromo ? validatedPromo._id : null,
          },
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );
        if (response) {
          await setOrdered({
            price: total,
            ordered: response.data,
            stripeToken: stripeResponse,
          });
          setIsLoading([false, false, false]);
          handleChangeStep(3);
        }
      } else {
        alert.show("Carte non Valid", { type: "error" });
        setIsLoading(false, false, false);
      }
    } catch (error) {
      alert.show(error.response.data.message, { type: "error" });
    }
  };

  return !isLoading[0] ? (
    <div className="order-payment">
      <h2>Paiement</h2>
      <div className="payment-info">
        <ul>
          {data.map((elem, index) => (
            <li key={index}>
              {elem.name}
              <span>({elem.perfume})</span>
            </li>
          ))}
        </ul>

        <div>
          {" "}
          <span>Sous-total :</span> {intlFormat(subTotal)}
        </div>
        <div>
          Frais de livraison par {delivery} : {intlFormat(deliveryFees)}
        </div>
        <div>
          Frais divers (emballage,transactionnel...) : {intlFormat(variousFees)}
        </div>
        {validatedPromo ? (
          <div>
            <p>{validatedPromo.description}</p>
          </div>
        ) : (
          <div>
            Code promotionnel :
            <input type="text" value={codePromo} onChange={handleChangeCode} />
            {isLoading[1] ? (
              <Loader type="Circles" width={30} height={30} color="#618F92" />
            ) : (
              <button onClick={submitCode} className="btn">
                Validez
              </button>
            )}
          </div>
        )}
        <div>
          <span>Total de votre commande :</span> {total && intlFormat(total)}
        </div>
      </div>

      <form onSubmit={submitCard}>
        <h3>Informations de paiement</h3>
        <div className="card-element">
          <CardElement />
        </div>
        {isLoading[2] ? (
          <div className="loader">
            <Loader type="Circles" width={30} height={30} color="#618F92" />
          </div>
        ) : (
          <div className="btn-payment">
            <button
              onClick={() => handleChangeStep(1)}
              className="btn btn-modal"
            >
              Retour
            </button>
            <button type="submit" className="btn">
              Validez
            </button>
          </div>
        )}
      </form>
    </div>
  ) : (
    <div className="loading">
      <Loader type="Circles" width={30} height={30} color="#618F92" />
    </div>
  );
};

export default OrderPayment;
