import "./PdfView.css";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cgv from "../../assets/doc/CGV.pdf";
import mentionsLegales from "../../assets/doc/legal_notice.pdf";

const PdfView = () => {
  const location = useLocation();

  const slug = location.pathname.split("/")[2];
  const pdfFile = slug === "cgv" ? cgv : mentionsLegales;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scaleDocument, setScaleDocument] = useState(1.5);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const onPageLoadSuccess = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleChangePage = (direction) => {
    if (direction === "left" && pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    } else {
      if (pageNumber < numPages && direction !== "left") {
        setPageNumber(pageNumber + 1);
      }
    }
  };

  useEffect(() => {
    const sizeScreen = () => {
      const size = window.innerWidth;
      if (size >= 950) {
        setScaleDocument(1.5);
      }
      if (size < 950) {
        setScaleDocument(1);
      }
      if (size < 640) {
        setScaleDocument(0.8);
      }
      if (size < 500) {
        setScaleDocument(0.5);
      }
    };
    window.addEventListener("resize", sizeScreen);
    return () => {
      window.removeEventListener("resize", sizeScreen);
    };
  }, []);

  return (
    <div className="pages">
      <div className="pdf-container">
        <Document
          className="document-container"
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <div className="user-action">
            <a href={pdfFile} download>
              <button className="btn">
                <FontAwesomeIcon className="icon-user-action" icon="download" />
                Télécharger
              </button>
            </a>

            <button
              className="btn"
              onClick={() => {
                const fileToPrint = document.getElementById("file");
                return fileToPrint.contentWindow.print();
              }}
            >
              <iframe
                style={{ display: "none" }}
                title="pdf"
                id="file"
                src={pdfFile}
              ></iframe>
              <FontAwesomeIcon className="icon-user-action" icon="print" />
              Imprimer
            </button>
          </div>
          <Page
            scale={scaleDocument}
            pageNumber={pageNumber}
            onRenderSuccess={onPageLoadSuccess}
            canvasBackground="#E4FDD9"
          />
          <p>
            <FontAwesomeIcon
              onClick={() => handleChangePage("left")}
              className="icon-arrow-pdf"
              icon="arrow-left"
            />
            Page {pageNumber} sur {numPages}
            <FontAwesomeIcon
              onClick={handleChangePage}
              className="icon-arrow-pdf"
              icon="arrow-right"
            />
          </p>
        </Document>
      </div>
    </div>
  );
};

export default PdfView;
