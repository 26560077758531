import "./NewProduct.css";
import {
  MyDropZone,
  InputTextProject,
  InputSelectProject,
} from "../FormikInputs/FormikInputs";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Loader from "react-loader-spinner";
import axios from "axios";
import { useAlert } from "react-alert";

const NewProduct = ({ data, token }) => {
  const alert = useAlert();

  /* Add new category */
  const newCategory = async (arrCategories, newCategory) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_URL}/backoffice/config`,
        { categories: arrCategories },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );
      return alert.show(
        `La nouvelle catégorie ${newCategory} a bien était enregistré`
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  /* Submit form */
  const onSubmitNewProduct = async (values, setSubmitting) => {
    try {
      const data = new FormData();
      data.append("picture", values.files[0]);
      const keys = Object.keys(values);
      keys.forEach(
        (key) => key !== "files" && data.append(`${key}`, values[key])
      );
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/backoffice/product`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: "Bearer " + token,
          },
        }
      );
      if (response.status === 200) {
        setSubmitting(false);
        alert.show(response.data.message, { type: "success" });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const validateSchemaProduct = Yup.object().shape({
    name: Yup.string().required("Obligatoire"),
    description: Yup.string().required("Obligatoire"),
    price: Yup.number()
      .typeError("Doit être un nombre")
      .min(1, "1 minimum")
      .required("Obligatoire"),
    quantity: Yup.number()
      .typeError("Doit être un nombre")
      .min(1, "1 minimum")
      .required("Obligatoire"),
    weightTotal: Yup.number()
      .typeError("Doit être un nombre")
      .min(1, "1 minimum")
      .required("Obligatoire"),
    burnTime: Yup.number()
      .typeError("Doit être un nombre")
      .min(1, "1 minimum")
      .required("Obligatoire"),
    wick: Yup.number()
      .typeError("Doit être un nombre")
      .min(1, "1 minimum")
      .required("Obligatoire"),
    productTime: Yup.number()
      .typeError("Doit être un nombre")
      .min(1, "1 minimum")
      .required("Obligatoire"),
    waxWeight: Yup.number()
      .typeError("Doit être un nombre")
      .min(1, "Le nombre doit être positif et supérieur 0")
      .required("Obligatoire"),
    category: Yup.string()
      .required("Vous devez choisir une catégorie")
      .test("new", ` Entrez un nouvelle catégorie`, (value) => value !== "new"),
  });

  return (
    <Formik
      initialValues={{
        name: "",
        description: "",
        color: "",
        price: "",
        category: "",
        quantity: "",
        burnTime: "",
        wick: "",
        productTime: "",
        waxWeight: "",
        weightTotal: "",
        files: null,
      }}
      onSubmit={(values, { setSubmitting }) =>
        onSubmitNewProduct(values, setSubmitting)
      }
      validationSchema={validateSchemaProduct}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form className="new-product">
          <InputTextProject
            label="Nom :"
            name="name"
            placeholder="Nom du produit"
          />
          <InputTextProject
            label="Déscription :"
            name="description"
            placeholder="Déscription du produit"
          />
          <InputSelectProject
            name="color"
            label="Couleur :"
            categories={data.colors}
          />
          <InputTextProject
            label="Prix :"
            name="price"
            type="number"
            placeholder="Prix du produit"
          />

          <InputSelectProject
            name="category"
            label="Catégorie :"
            className="categories"
            categories={data.categories}
            newCategory={newCategory}
          />
          <InputTextProject
            label="Quantité en stock :"
            name="quantity"
            type="number"
            placeholder="Quantité en stock"
          />
          <InputTextProject
            label="Temps de brulage :"
            name="burnTime"
            type="number"
            placeholder="Temps de brulage (en heure)"
          />
          <InputTextProject
            label="Nombre de mèches :"
            name="wick"
            type="number"
            placeholder="Nombre de mèches"
          />
          <InputTextProject
            label="Temps de production :"
            name="productTime"
            type="number"
            placeholder="Temps de production (en jour)"
          />
          <InputTextProject
            label="Poids de cire :"
            name="waxWeight"
            type="number"
            placeholder="Poids de cire (en gramme)"
          />
          <InputTextProject
            label="Poids Total pour transport :"
            name="weightTotal"
            type="number"
            placeholder="Poids Total pour transport (en gramme)"
          />
          <MyDropZone
            label="Photo du produit :"
            setFieldValue={setFieldValue}
            name="picture"
            max={1}
          />
          {isSubmitting ? (
            <Loader
              className="loader"
              type="Circles"
              color="#618f92"
              height={60}
              width={60}
            />
          ) : (
            <button type="submit" className="btn btn-new-product">
              Enregistrez
            </button>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default NewProduct;
