import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "react-loader-spinner";
import ProductDetails from "../components/Product/ProductDetails";
import ProductUpdate from "../components/Product/ProductUpdate";

const Article = ({ admin, edit, token, openModal }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/product/${id}`
        );
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [id]);

  return isLoading ? (
    <div className="pages loading">
      <Loader type="Circles" color="#618f92" height={60} width={60} />
    </div>
  ) : (
    <div className="pages container">
      {!edit ? (
        <ProductDetails
          data={data}
          token={token}
          admin={admin}
          openModal={openModal}
        />
      ) : admin ? (
        <ProductUpdate token={token} data={data} />
      ) : (
        <div className="pages">
          <h2 style={{ textAlign: "center", padding: 20 }}>
            Vous n'êtes pas autorisé sur cette page
          </h2>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={() => navigate("/")}>Retour à l'acceuil</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Article;
