import React, { useState, useRef, useEffect } from "react";

import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ";
import { transform, fromLonLat } from "ol/proj";
import { toStringXY } from "ol/coordinate";
import Zoom from "ol/control/Zoom";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Icon, Style } from "ol/style";
import Overlay from "ol/Overlay";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconFeaturesHouse from "../Map/house.svg";
import iconFeaturesMarker from "../Map/location-dot.svg";

const MapWrapper = () => {
  const [map, setMap] = useState();
  const [featuresLayer, setFeaturesLayer] = useState();
  const [selectedCoord, setSelectedCoord] = useState();
  const [markerName, setMarkerName] = useState("");

  const mapElement = useRef();
  const mapRef = useRef();
  mapRef.current = map;

  useEffect(() => {
    //create and add vector source layer
    const initalFeaturesLayer = new VectorLayer({
      source: new VectorSource(),
    });

    const markerFeature = new Feature({
      geometry: new Point(fromLonLat([0.599776, 45.667509])),
      name: "Au fil des désirs",
    });

    const styleMarker = new Style({
      image: new Icon({
        anchor: [0.5, 46],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: iconFeaturesHouse,
        imgSize: [20, 20],
      }),
    });
    markerFeature.setStyle(styleMarker);

    initalFeaturesLayer.setSource(
      new VectorSource({
        features: [markerFeature], // make sure features is an array
      })
    );

    /* Features control */
    const controlZoom = new Zoom({
      zoomInLabel: document.getElementById("zoom-plus"),
      zoomOutLabel: document.getElementById("zoom-minus"),
    });

    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        new TileLayer({
          source: new XYZ({
            attributions: document.getElementById("attribution"),
            url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
          }),
        }),
        initalFeaturesLayer,
      ],
      view: new View({
        projection: "EPSG:3857",
        center: fromLonLat([0.599776, 45.667509]),
        zoom: 14,
        minZoom: 12,
        maxZoom: 18,
      }),
      controls: [controlZoom],
    });
    initialMap.on("click", handleMapClick);

    const element = document.getElementById("popup");

    const popup = new Overlay({
      element: element,
      positioning: "bottom-center",
      stopEvent: false,
      id: "overMarker",
    });
    initialMap.addOverlay(popup);

    // display popup on click
    initialMap.on("click", (event) => {
      const feature = initialMap.forEachFeatureAtPixel(
        event.pixel,
        (feature) => {
          return feature;
        }
      );
      if (feature) {
        popup.setPosition(event.coordinate);
        setMarkerName(feature.get("name"));
      }
    });

    setMap(initialMap);
    setFeaturesLayer(initalFeaturesLayer);
  }, []);

  /* Close popup function */
  const onClickPopupClose = () => {
    map.getOverlayById("overMarker").setPosition(undefined);
    setMarkerName("");
  };

  useEffect(() => {
    let markerCoord;
    if (selectedCoord !== undefined) {
      markerCoord = new Feature({
        geometry: new Point(fromLonLat(selectedCoord)),
        name: "Votre marqueur",
      });
      const styles = new Style({
        image: new Icon({
          color: "#dfa06e",
          src: iconFeaturesMarker,
          imgSize: [20, 20],
        }),
      });
      markerCoord.setStyle(styles);

      if (featuresLayer.getSource().getFeatures().length > 1) {
        const featureToDelete = featuresLayer.getSource().getFeatures()[1];
        featuresLayer.getSource().removeFeature(featureToDelete);
      }

      featuresLayer.getSource().addFeature(markerCoord);
    }
  }, [featuresLayer, selectedCoord]);

  useEffect(() => {
    if (selectedCoord) {
      map.getView().setCenter(fromLonLat(selectedCoord));
    }
  }, [selectedCoord, map]);

  const handleMapClick = (event) => {
    const feature = mapRef.current.forEachFeatureAtPixel(
      event.pixel,
      (feature) => {
        return feature;
      }
    );

    if (!feature) {
      const clickedCoord = mapRef.current.getCoordinateFromPixel(event.pixel);
      const transormedCoord = transform(clickedCoord, "EPSG:3857", "EPSG:4326");
      setSelectedCoord(transormedCoord);
    }
  };

  return (
    <div className="wrapper-container">
      <div ref={mapElement} className="map-container"></div>

      <div className="clicked-coord-label">
        {selectedCoord ? (
          <p>Coordonnées du marqueur : {toStringXY(selectedCoord, 5)} </p>
        ) : null}
      </div>
      <FontAwesomeIcon
        className="btn"
        size="lg"
        id="zoom-plus"
        icon="search-plus"
      />
      <FontAwesomeIcon
        className="btn"
        size="lg"
        id="zoom-minus"
        icon="search-minus"
      />
      <div id="popup" className="ol-popup">
        <button
          onClick={onClickPopupClose}
          id="popup-closer"
          className="ol-popup-closer"
        ></button>
        <div id="popup-content">{markerName}</div>
      </div>
      <a
        id="attribution"
        href="https://www.openstreetmap.org/copyright"
        target="_blank"
        rel="noreferrer"
      >
        &copy; OpenStreetMap contributors
      </a>
    </div>
  );
};

export default MapWrapper;
