import "./MondialModal.css";
import Modal from "react-modal";
import WidgetMondial from "./WidgetMondial";

/* Modal */
const customStyles = {
  overlay: { zIndex: 99 },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderColor: "#618f92",
    background: "#E4FDD9",
  },
};
Modal.setAppElement("#root");

const MondialModal = ({ isOpen, closeModal }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const inputCode = document.querySelector("#ParcelShopCode");
    const inputInfoShop = document.querySelector("#selectedAdress");
    if (inputCode.value && inputInfoShop.value) {
      const valueInput = JSON.parse(inputInfoShop.value);
      valueInput.shopCode = inputCode.value;
      closeModal(valueInput);
    } else {
      closeModal();
    }
  };
  return (
    <Modal
      id="mondial-modal"
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <form onSubmit={handleSubmit}>
        <WidgetMondial />
        <input type="hidden" id="ParcelShopCode" />
        <input type="hidden" id="selectedAdress" />
        <div className="btn-modal-modial">
          <button className="btn" type="submit">
            Validez ce point relay
          </button>
          <button className="btn" onClick={closeModal}>
            Annulez
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default MondialModal;
