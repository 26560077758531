import "./UpdateAvatar.css";
import { Formik, Form } from "formik";
import { MyDropZone } from "../FormikInputs/FormikInputs";
import axios from "axios";
import Loader from "react-loader-spinner";

const UpdateAvatar = ({ onClose, token, upDateAvatar, handleChangeData }) => {
  const onSubmit = async (value) => {
    try {
      const data = new FormData();
      data.append("avatar", value.files[0]);
      const response = await axios.put(
        `${process.env.REACT_APP_URL}/user/profile/update`,
        data,
        {
          headers: {
            authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      upDateAvatar();
      onClose();
      handleChangeData();
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Formik initialValues={{ avatar: "" }} onSubmit={onSubmit}>
      {({ isSumitting, setFieldValue }) => (
        <Form id="profile-drop-zone">
          <MyDropZone
            setFieldValue={setFieldValue}
            max={1}
            minus={true}
            both={true}
          />
          {!isSumitting ? (
            <>
              <button className="btn-submit-avatar" type="submit">
                Enregistrez
              </button>
              <button
                type="reset"
                onClick={onClose}
                className="btn-cancel-avatar"
              >
                Annulez
              </button>
            </>
          ) : (
            <div className="loader">
              <Loader type="Circles" width={40} color="#85b4aa" />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default UpdateAvatar;
