import "../assets/css/Basket.css";
import { useState, useEffect, useCallback } from "react";
import Loader from "react-loader-spinner";
import axios from "axios";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import OrderElement from "../components/Order/OrderElement";

const Basket = ({ token, openModal }) => {
  const alert = useAlert();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const [workArray, setWorkArray] = useState([]);
  const [products, setProducts] = useState();

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/user/purchase`,
        {
          headers: {
            authorization: "Bearer " + token,
          },
          params: { basket: true },
        }
      );
      const promotions = await axios.get(
        `${process.env.REACT_APP_URL}/promotion?articles=true`
      );
      setWorkArray(
        response.data.map((product) => {
          promotions.status === 200 &&
            promotions.data.forEach((promo) => {
              if (product.product === promo.product._id) {
                product.reduction = promo.reduction;
              }
            });
          return {
            quantity: product.quantity || 1,
            price: product.price,
            weight: product.weight,
            perfume: product.perfume,
            product: product.product,
            _id: product._id,
            reduction: product.reduction || false,
          };
        })
      );
      setData(response.data);
    } catch (error) {
      alert.show(error.response.data.message, { type: "error" });
    }
  }, [alert, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const removeProduct = async (productId) => {
      setData();
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_URL}/user/basket/delete/${productId}`,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        );
        fetchData();
        alert.show(response.data.message, { type: "success" });
      } catch (error) {
        console.log(error.response.message);
        alert.show(error.response.data.message, { type: "error" });
      }
    };
    if (!data) {
      setIsLoading(true);
      return;
    } else {
      setProducts(
        data.map((product, index) => {
          return (
            <OrderElement
              key={product._id}
              data={product}
              className="basket-element"
              index={index}
              workArray={workArray}
              setWorkArray={setWorkArray}
              removeProduct={removeProduct}
            />
          );
        })
      );
      setIsLoading(false);
    }
  }, [alert, data, token, fetchData, workArray]);

  const handleClickOrdered = async (btn) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_URL}/user/basket/update`,
        { basket: workArray },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      );
      if (btn === "order") {
        navigate("/order");
      } else {
        navigate("/");
      }
    } catch (error) {
      alert.show(error.response.data.message);
    }
  };

  return (
    <div className="pages">
      {!token ? (
        <div className="basket-no-user">
          <h2>Vous devez être connecté pour consulter votre panier</h2>
          <div className="btn-no-user-container">
            <button onClick={() => openModal(1)} className="btn btn-no-user">
              Connectez Vous
            </button>
            <button onClick={() => openModal(0)} className="btn">
              Créé un compte
            </button>
          </div>
        </div>
      ) : isLoading ? (
        <div className="loading">
          <Loader type="Circles" width={60} height={60} color="#618f92" />{" "}
        </div>
      ) : data && data.length !== 0 ? (
        <>
          <div className="basket-container container">
            <div className="basket-element">
              <div className="basket-title">Nom</div>
              <div className="basket-title">Image</div>
              <div className="basket-title">Parfum</div>
              <div className="basket-title">Nombres d'article</div>
              <div className="basket-title">Prix unitaire</div>
              <div className="basket-title">Total</div>
              <div className="basket-title">Supprimer</div>
            </div>
            {products}
            <div className="btn-basket">
              <button
                onClick={() => handleClickOrdered("order")}
                className="btn"
              >
                Commandez
              </button>
              <button onClick={handleClickOrdered} className="btn">
                Enregistrez et quitter
              </button>
              <button
                onClick={() => navigate("/")}
                className="btn btn-backOffice"
              >
                Quitter
              </button>
            </div>
          </div>
          <div className="info-basket container">
            <span>* Article en promotion</span>
          </div>
        </>
      ) : (
        <div className="empty-basket">
          <h2>Vous n'avez aucun produit dans votre panier</h2>
          <span className="empty-basket-img">😅</span>
          <button
            onClick={() => navigate("/products")}
            className="btn btn-modal btn-empty-basket"
          >
            Consultez les produits
          </button>
        </div>
      )}
    </div>
  );
};

export default Basket;
