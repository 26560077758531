import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { MyDropZone, InputTextProject } from "../FormikInputs/FormikInputs";
import Loader from "react-loader-spinner";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

const ProductUpdate = ({ data, token }) => {
  const alert = useAlert();
  const navigate = useNavigate();

  const validateSchemaProduct = Yup.object().shape({
    name: Yup.string(),
    description: Yup.string(),
    price: Yup.number().typeError("Doit être un nombre").min(1, "1 minimum"),
    weightTotal: Yup.number()
      .typeError("Doit être un nombre")
      .min(1, "1 minimum"),
    burnTime: Yup.number().typeError("Doit être un nombre").min(1, "1 minimum"),
    wick: Yup.number().typeError("Doit être un nombre").min(1, "1 minimum"),
    productTime: Yup.number()
      .typeError("Doit être un nombre")
      .min(1, "1 minimum"),
    waxWeight: Yup.number()
      .typeError("Doit être un nombre")
      .min(1, "Le nombre doit être positif et supérieur 0"),
  });

  const onSubmit = async (values, setSubmitting) => {
    try {
      const keys = Object.keys(values);
      const testChanged = (values, keys) => {
        let result = false;
        keys.forEach((key) =>
          values[key] === "" || values[key] === null ? null : (result = true)
        );
        return result;
      };

      if (testChanged(values, keys)) {
        setSubmitting(true);
        const formData = new FormData();
        if (values.files !== null) {
          formData.append("picture", values.files[0]);
        }
        keys.forEach(
          (key) =>
            key !== "files" &&
            values[key] !== "" &&
            formData.append(`${key}`, values[key])
        );
        const response = await axios.put(
          `${process.env.REACT_APP_URL}/product/update/${data._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: "Bearer " + token,
            },
          }
        );
        if (response.status === 200) {
          alert.show(response.data.message, { type: "success" });
          navigate(-1);
        }
      } else {
        alert.show("Vous n'avez fait aucun changement", {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="container-article">
      <Formik
        initialValues={{
          name: data.name || "",
          description: data.description,
          price: data.price || "",
          burnTime: data.burnTime || "",
          wick: data.wick || "",
          productTime: data.productTime || "",
          waxWeight: data.waxWeight || "",
          weightTotal: data.weightTotal || "",
          files: data.picture || null,
        }}
        onSubmit={(values, { setSubmitting }) =>
          onSubmit(values, setSubmitting)
        }
        validationSchema={validateSchemaProduct}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <div className="info-article">
              <div className="info">
                <h4>Photo :</h4>
                <MyDropZone
                  name="files"
                  setFieldValue={setFieldValue}
                  max={1}
                  classnamelabel="no-label"
                />
              </div>
              <div className="info">
                <h4>Nom :</h4>
                <InputTextProject
                  name="name"
                  classnamelabel="no-label"
                  classnameinput="update-product"
                />
              </div>
              <div className="info">
                <h4>Catégorie :</h4>
                <p>
                  {data.category}
                  <span style={{ color: "#dfa06e" }}>
                    {" "}
                    Valeur non modifiable
                  </span>
                </p>
              </div>
              <div className="info">
                <h4>Description :</h4>
                <InputTextProject
                  name="description"
                  classnamelabel="no-label"
                  classnameinput="update-product"
                />
              </div>
              <div className="info">
                <h4>Temps de brûlage :</h4>
                <InputTextProject
                  name="burnTime"
                  type="number"
                  classnamelabel="no-label"
                  classnameinput="update-product"
                />
                <span>Heures</span>
              </div>
              <div className="info">
                <h4>Nombre de mèches :</h4>
                <InputTextProject
                  name="wick"
                  type="number"
                  classnamelabel="no-label"
                  classnameinput="update-product"
                />
              </div>
              <div className="info">
                <h4>Poids approximatif de cire :</h4>
                <InputTextProject
                  name="waxWeight"
                  type="number"
                  classnamelabel="no-label"
                  classnameinput="update-product"
                />
                <span> Grammes</span>
              </div>
              <div className="info">
                <h4>Temps de production avant envoi :</h4>
                <InputTextProject
                  name="productTime"
                  type="number"
                  classnamelabel="no-label"
                  classnameinput="update-product"
                />
                <span>Jour{data.productTime > 1 && "s"}</span>
              </div>
              <div className="info">
                <h4>Poids total du produit :</h4>
                <InputTextProject
                  name="weightTotal"
                  type="number"
                  classnamelabel="no-label"
                  classnameinput="update-product"
                />
                <span> Grammes</span>
              </div>
              <div className="info">
                <h4>Prix :</h4>
                <InputTextProject
                  name="price"
                  type="number"
                  classnamelabel="no-label"
                  classnameinput="update-product"
                />
                <span> €</span>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {isSubmitting ? (
                <Loader type="Circles" color="#618f92" height={60} width={60} />
              ) : (
                <button className="btn" type="submit">
                  Enregistrez
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProductUpdate;
