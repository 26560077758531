import "./Signup.css";
import Modal from "react-modal";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  InputTextProject,
  InputPasswordProject,
  MyDropZone,
  CheckBoxProject,
} from "../FormikInputs/FormikInputs";
import axios from "axios";
import Loader from "react-loader-spinner";
import Cookies from "js-cookie";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";

/* Modal */
const customStyles = {
  overlay: { zIndex: 99 },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderColor: "#618f92",
    background: "#E4FDD9",
  },
};
Modal.setAppElement("#root");
/* Validation */

const validationShemaSignup = Yup.object().shape({
  userName: Yup.string().required("Obligatoire"),
  email: Yup.string().email("Invalide email").required("Obligatoire"),
  files: Yup.mixed(),
  password: Yup.string().min(6, "Minimum 6 caractères").required("Obligatoire"),
});

const Signup = ({ modalIsOpen, closeModal, toggleModal, setToken }) => {
  const alert = useAlert();

  return (
    <Modal
      id="signup"
      isOpen={modalIsOpen[0]}
      onRequestClose={() => closeModal(0)}
      style={customStyles}
    >
      <div className="btn-close-modal-container">
        <button className="btn btn-close-modal" onClick={() => closeModal(0)}>
          X
        </button>
      </div>
      <h1 className="signup-title">S'inscrire</h1>
      <Formik
        initialValues={{
          userName: "",
          email: "",
          files: null,
          password: "",
          newLetter: true,
        }}
        validationSchema={validationShemaSignup}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const formdata = new FormData();
            const keys = Object.keys(values);
            keys.forEach(
              (key) => key !== "files" && formdata.append(key, values[key])
            );
            if (values.files) {
              formdata.append("files", values.files[0]);
            }

            const response = await axios.post(
              `${process.env.REACT_APP_URL}/signup`,
              formdata,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response.status === 200) {
              Cookies.set("afdd_co_to", response.data.token, { secure: true });
              setToken(response.data.token);
              setSubmitting(false);
              closeModal(0);
              alert.show("Votre compte à bien été crée", {
                type: "success",
              });
            }
            return;
          } catch (error) {
            alert.show(error.response.data.message, { type: "error" });
          }
        }}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form className="signup-form">
            <InputTextProject
              classnameinput="input-modal"
              type="email"
              name="email"
              placeholder="Email"
            />
            <InputTextProject
              classnameinput="input-modal"
              name="userName"
              placeholder="Nom d'utilisateur"
            />
            <InputPasswordProject
              classnameinput="input-modal"
              name="password"
              placeholder="Mot de passe"
            />
            <div className="signup-avatar">
              <MyDropZone
                label="Avatar"
                name="files"
                setFieldValue={setFieldValue}
                max={1}
              />
            </div>

            <CheckBoxProject
              name="newLetter"
              label="Recevoir nos codes promotionnel"
              checked={values.newLetter}
              classnamelabel="checkbox-signup"
            />
            <p className="advertissement">
              En vous inscrivant sur notre site vous acceptez
              <br />
              <Link to="/legal_notice/mentions-legales" target="_blank">
                nos mentions légales et politique de confidentialité
              </Link>
            </p>
            {isSubmitting ? (
              <Loader
                className="loaderSignup"
                type="Circles"
                color="#618f92"
                height={60}
                width={60}
              />
            ) : (
              <button className="btn btn-modal" type="submit">
                Soumettre
              </button>
            )}
          </Form>
        )}
      </Formik>
      <div onClick={() => toggleModal(1)} className="toggle-modal">
        Déjà un compte ? Connectez-vous ?
      </div>
    </Modal>
  );
};

export default Signup;
