import "./App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import Signup from "./components/Signup/Signup";
import Login from "./components/Login/Login";
import ForgottenPassword from "./components/Login/ForgottenPassword";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBurn,
  faCheck,
  faEye,
  faEyeSlash,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faHandPointUp,
  faHeart,
  faHome,
  faShoppingBag,
  faPaperPlane,
  faShoppingCart,
  faEdit,
  faTrash,
  faPlus,
  faMinus,
  faCalculator,
  faSearchPlus,
  faSearchMinus,
  faSmileWink,
  faBars,
  faSearch,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCaretLeft,
  faStar,
  faStarHalf,
  faUserPen,
  faPencil,
  faBox,
  faArrowLeft,
  faArrowRight,
  faDownload,
  faPrint,
  faImagePortrait,
  faHandPointDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  faStar as starEmpty,
  faEnvelope,
  faLightbulb,
  faFontAwesome,
} from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import Cookies from "js-cookie";
import Home from "./pages/Home";
import PasswordUpdate from "./pages/PasswordUpdate";
import Product from "./pages/Product";
import BackOffice from "./pages/BackOffice";
import Article from "./pages/Article";
import Contact from "./pages/Contact";
import Loader from "react-loader-spinner";
import Order from "./pages/Order";
import Basket from "./pages/Basket";
import UpDateProfile from "./pages/UpDateProfile";
import SearchBar from "./components/SearchBar/SearchBar";
import SearchResult from "./pages/SearchResult";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Footer from "./components/Footer/Footer";
import PdfView from "./components/PdfView/PdfView";

const stripePromise = loadStripe(`${process.env.REACT_APP_API_KEY_STRIPE}`);

require("dotenv").config();

library.add(
  faBurn,
  faEye,
  faEyeSlash,
  faCheck,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faHandPointUp,
  faHeart,
  faHome,
  faShoppingBag,
  faPaperPlane,
  faShoppingCart,
  faEdit,
  faTrash,
  faPlus,
  faMinus,
  faCalculator,
  faSearchPlus,
  faSearchMinus,
  faSmileWink,
  faBars,
  faSearch,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCaretLeft,
  faStar,
  faStarHalf,
  starEmpty,
  faUserPen,
  faEnvelope,
  faPencil,
  faBox,
  faArrowLeft,
  faArrowRight,
  faDownload,
  faPrint,
  faImagePortrait,
  faHandPointDown,
  faLightbulb,
  faFontAwesome
);

function App() {
  /* Modal */
  const [modalIsOpen, setModalIsOpen] = useState([false, false, false]);

  /* fetch info user */
  const [userInfo, setUserInfo] = useState(null);
  const [token, setToken] = useState(Cookies.get("afdd_co_to") || null);
  const [admin, setAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [upDateAvatar, setUpDateAvatar] = useState(false);

  /* fetch config */
  const [dataConfig, setDataConfig] = useState(null);
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);

  /* Modal function */
  const openModal = (index) => {
    const newArr = [...modalIsOpen];
    newArr[index] = true;
    setModalIsOpen(newArr);
    document.body.style.overflow = "hidden";
  };

  const closeModal = (index) => {
    const newArr = [...modalIsOpen];
    newArr[index] = false;
    setModalIsOpen(newArr);
    document.body.style.overflow = "auto";
  };

  const toggleModal = (modal) => {
    const newArrModal = modalIsOpen.map((elem, index) =>
      index === modal ? (elem = true) : (elem = false)
    );
    setModalIsOpen(newArrModal);
  };

  /* Info user function */
  useEffect(() => {
    const getInfoUser = async (userToken) => {
      try {
        if (userToken) {
          const response = await axios.get(
            `${process.env.REACT_APP_URL}/user`,
            {
              params: {
                token: userToken,
              },
            }
          );
          response.data.account.email = response.data.email;
          setUserInfo(response.data.account);
          if (response.data.status_id === 0) {
            setAdmin(true);
          }
          setIsLoading(false);
        } else {
          setUserInfo(null);
          setIsLoading(false);
          setAdmin(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    const configuration = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/backoffice`
        );
        setDataConfig(response.data);
        if (response.status === 200) {
          setIsLoadingConfig(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    };
    configuration();
    getInfoUser(token);
  }, [token, upDateAvatar]);

  const handleChangeAvatar = () => {
    setUpDateAvatar(!upDateAvatar);
  };

  return (
    <Router>
      {isLoading || isLoadingConfig ? (
        <div className="loading">
          <Loader type="Circles" width={60} height={60} color="#618f92" />
        </div>
      ) : (
        <>
          <Header
            openModal={openModal}
            setToken={setToken}
            token={token}
            admin={admin}
            userInfo={userInfo}
            dataConfig={dataConfig}
          />
          <Signup
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            toggleModal={toggleModal}
            setToken={setToken}
          />
          <Login
            modalIsOpen={modalIsOpen}
            openModal={openModal}
            closeModal={closeModal}
            toggleModal={toggleModal}
            setToken={setToken}
            setUserInfo={setUserInfo}
          />
          <ForgottenPassword
            modalIsOpen={modalIsOpen}
            openModal={openModal}
            closeModal={closeModal}
            setToken={setToken}
            userInfo={userInfo}
          />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <SearchBar dataConfig={dataConfig} />
                  <Home />
                </>
              }
            />
            <Route
              path="/products"
              element={
                <>
                  <SearchBar dataConfig={dataConfig} />
                  <Product
                    dataConfig={dataConfig}
                    token={token}
                    openModal={openModal}
                  />
                </>
              }
            />
            <Route
              path="/user/password/update/:tmptoken"
              element={<PasswordUpdate setToken={setToken} />}
            />
            <Route
              path="/backoffice"
              element={<BackOffice admin={admin} token={token} />}
            />
            <Route
              path="/products/:id"
              element={
                <Article
                  admin={admin}
                  token={token}
                  edit={false}
                  openModal={openModal}
                />
              }
            />
            <Route
              path="/product/update/:id"
              element={<Article admin={admin} token={token} edit={true} />}
            />
            <Route path="/contact" element={<Contact userInfo={userInfo} />} />
            <Route
              path="/order"
              element={
                <Elements stripe={stripePromise}>
                  <Order token={token} />
                </Elements>
              }
            />
            <Route
              path="/basket"
              element={<Basket token={token} openModal={openModal} />}
            />
            <Route
              path="/search"
              element={
                <>
                  <SearchBar dataConfig={dataConfig} />
                  <SearchResult
                    openModal={openModal}
                    token={token}
                    dataConfig={dataConfig}
                  />
                </>
              }
            />
            <Route
              path="/profile/update"
              element={
                <UpDateProfile
                  token={token}
                  setToken={setToken}
                  upDateAvatar={handleChangeAvatar}
                  openModal={openModal}
                />
              }
            />
            <Route path="/legal_notice/:slug" element={<PdfView />}></Route>
          </Routes>
          <Footer />
        </>
      )}
    </Router>
  );
}

export default App;
