import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import "./assets/googleFont/Unna/Unna-Regular.ttf";
import "./assets/googleFont/Unna/Unna-Italic.ttf";
import "./assets/googleFont/Unna/Unna-Bold.ttf";
import "./assets/googleFont/Unna/Unna-BoldItalic.ttf";
import "./assets/googleFont/Dancing_Script/DancingScript-VariableFont_wght.ttf";
import "./assets/googleFont/Lobster_Two/LobsterTwo-Regular.ttf";
import "./assets/googleFont/Lobster_Two/LobsterTwo-Bold.ttf";
import "./assets/googleFont/Lobster_Two/LobsterTwo-BoldItalic.ttf";
import "./assets/googleFont/Lobster_Two/LobsterTwo-Italic.ttf";

const AlertTemplate = ({ options, message, close }) => {
  if (options.type === "info") {
    document.body.style.overflow = "hidden";
  }
  return (
    <div
      className="container-alert"
      style={
        options.type === "success"
          ? {
              background: "#85B4AA",
              pointerEvents: "all",
              margin: "30px",
            }
          : options.type === "info"
          ? {
              border: "solid 2px #456b76",
              background: "#E4FDD9",
              pointerEvents: "all",
              margin: "30px",
            }
          : { background: "#dfa06e", pointerEvents: "all", margin: "30px" }
      }
    >
      <div className="close-alert">
        <button
          className="btn btn-alert"
          onClick={() => {
            if (options.type === "info") {
              document.body.style.overflow = "auto";
            }
            return close();
          }}
        >
          X
        </button>
      </div>
      {message}
      {options.type === "success" && " 😎"}
      {options.type === "error" && " 😅"}
    </div>
  );
};
const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 3000,
  offset: "30px",
  transition: transitions.SCALE,
};

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
